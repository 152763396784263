import React from 'react'
import { Button } from '@components/Basic/ButtonNbe'
import LbLogoSvg from '@assets/images/logo-lb-small.svg'
import { MdOutlineCheckCircleOutline } from 'react-icons/md'
import { Trans, useTranslation } from 'react-i18next'
import {
  Wrapper,
  ButtonWrapper,
  Header,
  Paragraph,
  Body,
  Label,
  Disclaimer,
} from './styled'
import { InputField } from '@components/Form/InputField'
import { BiShieldQuarter } from 'react-icons/bi'

interface Props {
  appLogoUri?: string
  onSave: () => void
  onCancel: () => void
  integrationName: string
  onEditIntegrationName: (name: string) => void
  isUpdatingIntegration: boolean
}

export const IncomingDataFinalStep: React.FC<Props> = ({
  onSave,
  integrationName,
  onEditIntegrationName,
  onCancel,
  isUpdatingIntegration,
}) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Header>
        <img src={LbLogoSvg} width='40' />
      </Header>
      <Body>
        <MdOutlineCheckCircleOutline color={'#2ECD72'} size={100} />
        <Paragraph>{t('incomingDataPopup.paragraphLastStepWebhook')}</Paragraph>
        <Label>{t('nbee.bridgeBuilder.integrationName')}</Label>
        <div style={{ width: '450px' }}>
          <InputField
            name={'name'}
            type={'text'}
            value={integrationName}
            onChange={(e) => onEditIntegrationName(e.target.value)}
            style={{ textAlign: 'center' }}
          />
        </div>
        <ButtonWrapper>
          <Button
            style={{ width: '100%' }}
            $variant={'primary'}
            $size={'large'}
            onClick={onSave}
            disabled={isUpdatingIntegration || integrationName === ''}
          >
            {t('common.save')}
          </Button>
          <Button
            $variant={'link-primary'}
            style={{ textDecoration: 'underline', marginTop: '.5rem' }}
            onClick={onCancel}
          >
            {t('common.cancel')}
          </Button>
        </ButtonWrapper>
        <Disclaimer>
          <BiShieldQuarter size={'1.2rem'} />
          <div>
            <Trans
              ns={'all'}
              i18nKey={'integrationAuthPopup.disclaimerLine'}
              components={{
                a: <a />,
              }}
            />
          </div>
        </Disclaimer>
      </Body>
    </Wrapper>
  )
}
