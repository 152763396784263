import React from 'react'
import { Button } from '@components/Basic/ButtonNbe'
import { InputAutoSelectCopy } from '@components/InputAutoSelectCopy'
import LbLogoSvg from '@assets/images/logo-lb-small.svg'
import { useTranslation } from 'react-i18next'
import {
  Wrapper,
  ButtonWrapper,
  Header,
  Title,
  Paragraph,
  Body,
} from './styled'
import { useAppSelector } from '@app/store/hooks'

interface Props {
  appLogoUri: string
  onContinueRequest: () => void
}

export const IncomingDataInitialStep: React.FC<Props> = ({
  appLogoUri,
  onContinueRequest,
}) => {
  const { t } = useTranslation()
  const integrationListener = useAppSelector(
    (state) => state.integrationListener
  )

  return (
    <Wrapper>
      <Header>
        <img src={LbLogoSvg} width='40' alt='Logo description' />
      </Header>
      <Body>
        <Title>
          <img src={appLogoUri} width='45' alt='App logo' />
          <h2>{t('incomingDataPopup.titleFirstStepWebhook')}</h2>
        </Title>
        <Paragraph>
          {t('incomingDataPopup.paragraphFirstStepWebhook')}
        </Paragraph>
        {integrationListener.data ? (
          <InputAutoSelectCopy
            // docUrl={'https://leadsbridge.com'}
            linkKey={t('incomingDataPopup.linkTextFirstStepWebhook')}
            label={'Copy this URL'}
            inputValue={integrationListener.data?.slug}
            tooltip={t('incomingDataPopup.inputCopyUrlTooltipWebhook')}
          />
        ) : null}
        <ButtonWrapper>
          <Button
            style={{ width: '100%' }}
            $variant={'primary'}
            onClick={onContinueRequest}
          >
            {t('incomingDataPopup.ctaRecognizedWebhook')}
          </Button>
        </ButtonWrapper>
      </Body>
    </Wrapper>
  )
}
