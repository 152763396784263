import React, { Fragment, useEffect, useState } from 'react'
import { Panel } from '@components/Panel'
import { Button } from '@components/Basic/ButtonNbe'
import { PageEditor } from '@app/layouts/PageEditor'
import { appRoutes } from '@app/routes'
import { useTranslation } from 'react-i18next'
import { makeNbeeSteps } from '@features/nbee/utils'
import { HeaderSearch } from '@features/nbee/FieldsMappingForm/HeaderSearch'
import { FormInner } from '@features/nbee/FieldsMappingForm/FormInner'
import { AddFilterButton } from '@features/nbee/AddFilterButton'
import { BridgeFilterCondition, BridgeFormValues } from 'Nbee'
import {
  ApiBridgeAvailablePermissionCheckResponse,
  ApiBridgeFieldsList,
} from 'BackendApi'
import { Formik, Form, FormikHelpers } from 'formik'
import { useNavigate } from 'react-router-dom'
import { makeFieldMappingValidationSchema } from '@features/nbee/FieldsMappingForm/validation'
import { SendTestLeadFeedback } from '@features/nbee/FieldsMappingForm/SendTestLeadFeedback'
import { ButtonTestBeforeProceeding } from '@features/nbee/FieldsMappingForm/ButtonTestBeforeProceeding'
import { getShowUnmappedInitialState } from '@features/nbee/FieldsMappingForm/utils'
import { ButtonSendTestLead } from '@features/nbee/FieldsMappingForm/SendTestLeadSubForm/ButtonSendTestLead'
import { FaAngleLeft } from 'react-icons/fa'
import { useGetBridgeAllFields } from '@app/api/getBridgeAllFields'
import { Loader } from '@app/components/Basic/Loader'
import { StatusBarBridge } from '@features/nbee/StatusBarBridge'
import { isInIframe } from '@app/utils/isInFrame'
import { useGetUserModules } from '@app/api/getUserModules'
import { TestConnectionView } from './TestConnectionView'

interface Props {
  bridgeId: number
  initialValues: BridgeFormValues
  onSubmit: (
    formValues: BridgeFormValues,
    formikHelpers: FormikHelpers<BridgeFormValues>
  ) => void
  isSavingApi?: boolean
  allBridgeFields?: ApiBridgeFieldsList
  isFirstBridge?: boolean
  bridgeFilterRulesList?: BridgeFilterCondition[]
  onRefetchBridgeFormValues?: () => void
  availablePermissionChecks?:
    | ApiBridgeAvailablePermissionCheckResponse
    | undefined
}

export type MinimumPlanNameTypes = {
  [key: string]: string
}
export const minimumPlanName: MinimumPlanNameTypes = {
  '6': 'Free',
  '7': 'pro',
  '8': 'team', // only a constant
  '9': 'appsumo',
  '10': 'starter',
}

export type FieldMappingScreenState =
  | 'mapping'
  | 'testing'
  | 'test-success'
  | 'test-error'

export const FieldsMappingForm: React.FC<Props> = ({
  bridgeId,
  initialValues,
  onSubmit,
  isSavingApi,
  allBridgeFields,
  isFirstBridge,
  bridgeFilterRulesList,
  onRefetchBridgeFormValues,
  availablePermissionChecks,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const steps = makeNbeeSteps(1, bridgeId)
  const isEmbedded = isInIframe()

  const testEnabled = initialValues?.ui?.testEnabled

  const {
    refetch: onRefetchFieldRequest,
    isRefetching: isReloadingAllBridgeFields,
  } = useGetBridgeAllFields(bridgeId)

  const {
    data: userModulesResponse,
    isLoading: isLoadingUserModules,
    error: apiUserModulesError,
  } = useGetUserModules()

  // this state handles which screen of the FieldsMappingForm to display
  const [screenState, setScreenState] =
    useState<FieldMappingScreenState>('mapping')

  const [showAllFields, setShowAllFields] = useState(
    getShowUnmappedInitialState(initialValues.fieldsMapping)
  )
  const [filterText, setFilterText] = useState('')

  const [testLeadDebugUri, setTestLeadDebugUri] = useState<string | undefined>()
  const validationSchema = makeFieldMappingValidationSchema()
  const destinationFields = allBridgeFields?.destination || []

  const isAPILoading = isReloadingAllBridgeFields || isLoadingUserModules

  const userModules = userModulesResponse?.modules || []
  const filterUserModules = userModules.find((i) => i.id === 'bridge-filter')
  const formulaUserModules = userModules.find((i) => i.id === 'bridge-formula')
  const fieldMappingModules = userModules.find((i) => i.id === 'fields-mapping')

  const maxFieldsToMap = testEnabled
    ? fieldMappingModules?.userDefaults?.maxFieldsMapping
    : undefined

  // console.log(validationSchema.validateSync(initialValues))
  useEffect(() => {
    if (filterText) {
      setShowAllFields(true)
    }
  }, [filterText])

  // UI
  const isInFieldMappingForm = screenState === 'mapping'
  const isInTestFieldMapping = screenState === 'testing'
  const isInTestFeedback =
    screenState === 'test-success' || screenState === 'test-error'

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values, helpers) => {
        onSubmit(values, helpers)
      }}
      validateOnMount
    >
      {(formikProps) => {
        const totalFieldsToMap = (formikProps.values.fieldsMapping || []).length
        const totalFieldsMapped = (
          formikProps.values.fieldsMapping || []
        ).filter(
          (f) => f.sourceFieldId || f.destinationText || f.formula?.id
        ).length

        const isTestLeadEnabled = formikProps.values.ui?.testEnabled

        const isBridgeEnabled = formikProps.values.ui?.isBridgeEnabled

        return (
          <Fragment>
            {isLoadingUserModules && <Loader $size='large' $active $dimmer />}
            <PageEditor
              steps={steps}
              activeStep={1}
              topbarValue={formikProps.values.name}
              infoBar={
                formikProps.values.ui?.bridgeId ? (
                  <StatusBarBridge
                    isVisible={!isFirstBridge}
                    bridgeId={formikProps.values.ui.bridgeId}
                  />
                ) : null
              }
              onTopbarValueChange={
                formikProps.values.ui?.isBridgeEnabled
                  ? undefined
                  : (newBridgeName) => {
                      formikProps.setFieldValue('name', newBridgeName)
                    }
              }
              hasTopBar={!isEmbedded}
              backlink={{
                url: '/bridges',
                label: t('nbee.bridgeBuilder.exitNbee'),
              }}
            >
              <Form>
                <Panel
                  head={
                    <HeaderSearch
                      sourceAppId={initialValues.source.appId}
                      destinationAppId={initialValues.destination.appId}
                      totalFields={totalFieldsToMap}
                      mappedFields={totalFieldsMapped}
                      inputValue={filterText}
                      onInputChange={setFilterText}
                      showAllFields={showAllFields}
                      onRefetchBridgeFieldRequest={onRefetchFieldRequest}
                      isReloadingAllBridgeFields={isAPILoading}
                      maxFields={maxFieldsToMap}
                      onShowAllFieldsRequest={(showAllFields) => {
                        setShowAllFields(showAllFields)
                      }}
                      isInTestScreen={isInTestFieldMapping || isInTestFeedback}
                    />
                  }
                  progressBarPercentage={isEmbedded ? 66 : undefined}
                  body={
                    <>
                      {isAPILoading ? (
                        <Loader
                          $text={t(
                            'nbee.fieldsMapping.refetchBridgeFieldLoaderMessage'
                          )}
                          $active={true}
                        />
                      ) : isInTestFieldMapping ? (
                        <>
                          <TestConnectionView
                            bridgeId={bridgeId}
                            availablePermissionChecks={
                              availablePermissionChecks
                            }
                          />
                        </>
                      ) : isInTestFeedback ? (
                        <SendTestLeadFeedback
                          status={
                            screenState === 'test-success' ? 'success' : 'error'
                          }
                          debugUri={testLeadDebugUri}
                          onBackToEditRequest={() => {
                            setScreenState('mapping')
                          }}
                          onProceedRequest={() => {
                            formikProps.submitForm()
                          }}
                        />
                      ) : (
                        <>
                          <AddFilterButton
                            initialFilterRules={bridgeFilterRulesList}
                            allSourceFields={allBridgeFields?.source}
                            bridgeId={bridgeId}
                            onRefetchBridgeFormValues={
                              onRefetchBridgeFormValues
                            }
                            isBridgeDisabled={isBridgeEnabled}
                            filterUserModules={filterUserModules}
                          />
                          {isSavingApi && (
                            <Loader $active $dimmer $size={'large'} />
                          )}
                          <FormInner
                            bridgeId={bridgeId}
                            filterText={filterText}
                            showAllFields={showAllFields}
                            onResetFilterRequest={() => {
                              setShowAllFields(true)
                              setFilterText('')
                            }}
                            allBridgeFields={allBridgeFields}
                            formulaUserModule={formulaUserModules}
                            totalFieldsMapped={totalFieldsMapped}
                            maxFields={maxFieldsToMap}
                          />
                        </>
                      )}
                    </>
                  }
                  footer={
                    // FOOTER FOR FIELD MAPPING SCREEN
                    isInFieldMappingForm
                      ? {
                          left: (
                            <Button
                              $hasIcon
                              type={'button'}
                              $variant={'link-secondary'}
                              disabled={isAPILoading}
                              onClick={() => {
                                navigate(
                                  appRoutes.nbeeStep1Edit.makeUrl(`${bridgeId}`)
                                )
                              }}
                            >
                              <FaAngleLeft />
                              {t('nbee.fieldsMapping.goPreviewStep')}
                            </Button>
                          ),
                          right: (
                            <div style={{ display: 'flex', gap: '1rem' }}>
                              {/* Open screenState to submit a test lead */}
                              {isTestLeadEnabled ? (
                                <ButtonTestBeforeProceeding
                                  scope={'test'}
                                  destinationFields={destinationFields}
                                  formikProps={formikProps}
                                  bridgeId={bridgeId}
                                  isDisabledFromParent={isAPILoading}
                                  onSuccess={() => {
                                    // when we check actual mapping, we take the user to the test screen
                                    setScreenState('testing')
                                  }}
                                />
                              ) : null}
                              {/* Save mapping */}

                              {!formikProps.values.ui?.isBridgeEnabled ? (
                                <ButtonTestBeforeProceeding
                                  scope={'save'}
                                  destinationFields={destinationFields}
                                  formikProps={formikProps}
                                  isDisabledFromParent={isAPILoading}
                                  isSavingFromParent={isSavingApi}
                                  bridgeId={bridgeId}
                                  onSuccess={() => {
                                    formikProps.submitForm()
                                  }}
                                />
                              ) : (
                                // in case of view only mode we just show a link to next step
                                <Button
                                  $variant={'primary'}
                                  type={'button'}
                                  onClick={() => {
                                    navigate(
                                      appRoutes.nbeeStep3.makeUrl(`${bridgeId}`)
                                    )
                                  }}
                                >
                                  {t('common.continue')}
                                </Button>
                              )}
                            </div>
                          ),
                        }
                      : // FOOTER FOR TEST FIELD MAPPING SCREEN
                      isInTestFieldMapping
                      ? {
                          left: (
                            <Button
                              $hasIcon
                              type={'button'}
                              $variant={'link-secondary'}
                              onClick={() => {
                                setScreenState('mapping')
                              }}
                            >
                              <FaAngleLeft />
                              {t('nbee.fieldsMapping.goPreviewStep')}
                            </Button>
                          ),
                          right: (
                            <ButtonSendTestLead
                              bridgeId={bridgeId}
                              onSendTestLead={({ status, debugUri }) => {
                                setScreenState(
                                  status === 'success'
                                    ? 'test-success'
                                    : 'test-error'
                                )
                                setTestLeadDebugUri(debugUri)
                              }}
                            />
                          ),
                        }
                      : // NO FOOTER FOR TEST FIELD MAPPING FEEDBACK
                        null
                  }
                />
              </Form>
            </PageEditor>
          </Fragment>
        )
      }}
    </Formik>
  )
}
