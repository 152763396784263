import React from 'react'
import { Button } from '@components/Basic/ButtonNbe'
import { useTranslation } from 'react-i18next'
import SuccessImage from '@assets/images/illustrations/field-mapping-test-success.svg'
import ErrorImage from '@assets/images/illustrations/field-mapping-test-error.svg'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { BridgeFormValues } from 'Nbee'

interface Props {
  onBackToEditRequest: () => void
  onProceedRequest: () => void
  status: 'success' | 'error'
  debugUri?: string
}

const Wrapper = styled.div`
  max-width: 490px;
  margin: 2rem auto 0;
  text-align: center;
`

const Image = styled.img`
  width: 250px;
  max-width: 100%;
  margin: 1rem auto;
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  padding: 2rem 3.5rem 0;
  button {
    flex: 1;
  }
`

export const SendTestLeadFeedback: React.VFC<Props> = ({
  onBackToEditRequest,
  onProceedRequest,
  status,
  debugUri,
}) => {
  const { t } = useTranslation()
  const { isSubmitting } = useFormikContext<BridgeFormValues>()

  return status === 'success' ? (
    <Wrapper>
      <h4>{t('nbee.fieldsMapping.testSuccessTitle')}</h4>
      {debugUri && (
        <div>
          <a
            style={{ display: 'inline-block' }}
            target={'_blank'}
            rel={'noopener noreferrer'}
            href={debugUri}
          >
            {t('nbee.fieldsMapping.testLeadDebugCta')}
          </a>
        </div>
      )}
      <Image
        src={SuccessImage}
        alt={t('nbee.fieldsMapping.testSuccessTitle')}
      />
      <ButtonWrapper>
        <Button
          type={'button'}
          $variant={'secondary'}
          onClick={onBackToEditRequest}
          disabled={isSubmitting}
        >
          {t('nbee.fieldsMapping.goBackToFieldsMapping')}
        </Button>

        <Button
          type={'button'}
          $variant={'primary'}
          onClick={onProceedRequest}
          disabled={isSubmitting}
          $loading={isSubmitting}
        >
          {t('nbee.fieldsMapping.saveAndPublish')}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  ) : (
    <Wrapper>
      <h4>{t('nbee.fieldsMapping.testErrorTitle')}</h4>
      {debugUri && (
        <div>
          <a
            style={{ display: 'inline-block' }}
            target={'_blank'}
            rel={'noopener noreferrer'}
            href={debugUri}
          >
            {t('nbee.fieldsMapping.testLeadDebugCta')}
          </a>
        </div>
      )}
      <Image src={ErrorImage} alt={t('nbee.fieldsMapping.testSuccessTitle')} />
      <ButtonWrapper>
        <a
          href={process.env.REACT_APP_SUPPORT_URL}
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          <Button type={'button'} $variant={'secondary'}>
            {t('nbee.fieldsMapping.chatWithSupport')}
          </Button>
        </a>

        <Button
          type={'button'}
          $variant={'primary'}
          onClick={onBackToEditRequest}
        >
          {t('nbee.fieldsMapping.goBackToFieldsMapping')}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  )
}
