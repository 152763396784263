import React from 'react'
import { FaCopy, FaMinus, FaPlus } from 'react-icons/fa'
import { Tooltip } from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import {
  IconButtonStyled,
  IconsWrapperStyled,
} from '@components/Basic/FiltersIcons/styled'

interface Props {
  handleCopy: () => void
  handleAdd: () => void
  handleDelete: () => void
  isDeleteDisabled?: boolean
  isAddDisabled?: boolean
  isCopyDisabled?: boolean
  isBridgeDisabled?: boolean
}

export const FiltersIcons: React.FC<Props> = ({
  handleCopy,
  handleAdd,
  handleDelete,
  isDeleteDisabled = false,
  isAddDisabled = false,
  isCopyDisabled = false,
  isBridgeDisabled,
}) => {
  const { t } = useTranslation()

  return (
    <div>
      <IconsWrapperStyled>
        <Tooltip
          id={'filter-copy'}
          place={'top'}
          variant={'light'}
          border={'#f6f7f8'}
          className={'reactTooltipFilterIcons'}
        >
          {t('nbee.filters.tooltipIconCopy')}
        </Tooltip>
        <IconButtonStyled
          type={'button'}
          disabled={isCopyDisabled || isBridgeDisabled}
          onClick={handleCopy}
          data-tooltip-id='filter-copy'
        >
          <FaCopy size={15} />
        </IconButtonStyled>
        <Tooltip
          id={'filter-delete'}
          place={'top'}
          variant={'light'}
          border={'#f6f7f8'}
          className={'reactTooltipFilterIcons'}
        >
          {t('nbee.filters.tooltipIconDelete')}
        </Tooltip>
        <IconButtonStyled
          type={'button'}
          disabled={isDeleteDisabled || isBridgeDisabled}
          onClick={handleDelete}
          data-tooltip-id='filter-delete'
        >
          <FaMinus size={15} />
        </IconButtonStyled>
        <Tooltip
          id={'filter-add'}
          place={'top'}
          variant={'light'}
          border={'#f6f7f8'}
          className={'reactTooltipFilterIcons'}
        >
          {t('nbee.filters.tooltipIconAdd')}
        </Tooltip>
        <IconButtonStyled
          type={'button'}
          disabled={isAddDisabled || isBridgeDisabled}
          onClick={handleAdd}
          data-tooltip-id='filter-add'
        >
          <FaPlus size={15} />
        </IconButtonStyled>
      </IconsWrapperStyled>
    </div>
  )
}
