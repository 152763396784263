import React, { Fragment, useState } from 'react'
import { Formik, Form } from 'formik'
import { BridgeFormValues } from 'Nbee'
import { FormInner } from './FormInner'
import { Button } from '@components/Basic/ButtonNbe'
import { PageEditor } from '@app/layouts/PageEditor'
import { Panel } from '@components/Panel'
import { useTranslation } from 'react-i18next'
import { validateBridgeChooserForm } from './validation'
import { isInIframe } from '@app/utils/isInFrame'

interface SimpleBridgeBuilderFormProps {
  initialValues: BridgeFormValues
  onSubmit: (formValues: BridgeFormValues) => void
}

export const BridgeChooserForm: React.FC<SimpleBridgeBuilderFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const isEmbedded = isInIframe()

  const [initialData, setInitialData] =
    useState<BridgeFormValues>(initialValues)

  /* const { data: popularConnections, isLoading: isLoadingConnections } =
    useFetchPopularConnections() */

  return (
    <Formik
      initialValues={initialData}
      validate={validateBridgeChooserForm}
      validateOnMount
      enableReinitialize={true}
      onSubmit={(values) => {
        onSubmit(values)
      }}
    >
      {(formikProps) => {
        return (
          <Fragment>
            <PageEditor
              pageTitle={t('nbee.bridgeChooser.title')}
              hasTopBar={!isEmbedded}
              backlink={{
                url: '/bridges',
                label: t('nbee.bridgeBuilder.exitNbee'),
              }}
            >
              <Form>
                <Panel
                  body={
                    <>
                      <FormInner />
                      {/* <CombinationsWrapper>
                        <h3>
                          {t('nbee.bridgeChooser.popularCombinationsTitle')}
                        </h3>
                        {isLoadingConnections ? (
                          <div style={{ height: '5rem' }}>
                            <Loader $dimmer $active />
                          </div>
                        ) : (
                          popularConnections?.data?.connections &&
                          popularConnections.data.connections.map(
                            (item, index) => (
                              <AppCombination
                                key={index}
                                appConnection={item}
                                handleClick={handleClick}
                              />
                            )
                          )
                        )}
                      </CombinationsWrapper> */}
                    </>
                  }
                  footer={{
                    left: null,
                    right: (
                      <>
                        <Button
                          type={'submit'}
                          $variant={'primary'}
                          disabled={
                            formikProps.isSubmitting || !formikProps.isValid
                          }
                          $loading={formikProps.isSubmitting}
                          $loadingText={'Checking...'}
                          $minWidth
                        >
                          {t('common.continue')}
                        </Button>
                      </>
                    ),
                  }}
                />
              </Form>
            </PageEditor>
          </Fragment>
        )
      }}
    </Formik>
  )
}
