import React, { useEffect, useState } from 'react'
import { CardDetails } from '@features/billing/CreditCardBox/CardDetails'
import { BoxWrapperWithSideImage } from '@features/billing/CreditCardBox/BoxWrapperWithSideImage'
import { StripePaymentProvider } from '@features/billing/stripe/StripePaymentProvider'
import { StripeForm } from '@features/billing/stripe/StripeForm'
import { CreditCardSubmitButtons } from '@features/billing/stripe/CreditCardSubmitButtons'
import { Button } from '@components/Basic/ButtonNbe'
import { useStoreCreditCard } from '@app/api/postCreditCard'
import { sendToast } from '@app/store/actions/ApplicationConfigurationActions'
import { parseApiError } from '@app/api/utils/error'
import { useAppDispatch } from '@app/store/hooks'
import { useGetUserInfo } from '@app/api/getUserInfo'
import { useTranslation } from 'react-i18next'
import { Loader } from '@components/Basic/Loader'
import styled from 'styled-components'
import { BsCreditCard2Back } from 'react-icons/bs'
import { trackEvent } from '@app/dataTracking'
import { makePricingTrackingStep } from '@app/dataTracking/utils'

export type CreditCardItem = {
  fullName?: string
  number: string
  expiration: string
  logoUri?: string
}

const ButtonWrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 8%;
    transform: translate(-50%, -50%);
  }
`

export const CreditCardBox: React.FC = () => {
  const [isAddingNewCard, setIsAddingNewCard] = useState(false)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const {
    data: userData,
    isLoading: isLoadingUser,
    refetch: refetchUserInfo,
  } = useGetUserInfo()
  const userBillingInfo = userData?.pricing

  const cards: CreditCardItem[] = (
    userBillingInfo && userBillingInfo.creditCard
      ? [
          {
            fullName: userBillingInfo.creditCardOwner || 'Saved card',
            number: userBillingInfo.creditCard,
            expiration: userBillingInfo.creditCardExpiration,
          },
        ]
      : []
  ).filter((card) => card.number && card.number !== 'N/A')

  const {
    mutate: storeCreditCardMutation,
    error: errorStoreCreditCard,
    isLoading: storeLoadingStateCreditCard,
  } = useStoreCreditCard()

  const storeCreditCard = (creditCardToken: string) => {
    storeCreditCardMutation(
      { creditCardToken: creditCardToken },
      {
        onSuccess: () => {
          refetchUserInfo()
          setIsAddingNewCard(false)
        },
      }
    )
  }

  // on error
  useEffect(() => {
    if (errorStoreCreditCard) {
      const parsedError = parseApiError(errorStoreCreditCard)
      dispatch(
        sendToast({
          title: 'Error',
          message: parsedError.message,
          color: 'negative',
        })
      )

      trackEvent({
        eventName: 'UnexpectedErrorThrown',
        feature: 'Pricing',
        step: makePricingTrackingStep(location.pathname),
        params: {
          custom: {
            errorCode: parsedError.code,
            errorDescription: parsedError.message,
          },
        },
      })
    }
  }, [errorStoreCreditCard])

  return isLoadingUser ? (
    <div>
      <Loader $active $dimmer $size={'large'} />
    </div>
  ) : isAddingNewCard ? (
    <StripePaymentProvider>
      <StripeForm onCreditCardTokenRetrieved={storeCreditCard}>
        {({ isLoading }) => (
          <CreditCardSubmitButtons
            isLoading={isLoading || storeLoadingStateCreditCard}
            onCancelRequest={() => {
              setIsAddingNewCard(false)
            }}
          />
        )}
      </StripeForm>
    </StripePaymentProvider>
  ) : (
    <BoxWrapperWithSideImage
      hasPadding
      title={t('billing.payment.creditCardBoxTitle')}
    >
      {cards && cards.length ? (
        cards.map((card, idx) => (
          <CardDetails
            key={idx}
            card={cards[0]}
            onEditCardRequest={() => {
              setIsAddingNewCard(true)
            }}
          />
        ))
      ) : (
        <div>
          <p>{t('billing.payment.noPaymentMethods')}</p>
          <ButtonWrapper>
            <BsCreditCard2Back color={'#fff'} />
            <Button
              onClick={() => {
                setIsAddingNewCard(true)
              }}
              $variant={'action'}
              $size={'large'}
              $fluid
              style={{ maxWidth: '270px' }}
            >
              {t('billing.payment.addCard')}
            </Button>
          </ButtonWrapper>
        </div>
      )}
    </BoxWrapperWithSideImage>
  )
}
