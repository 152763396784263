import React, { useEffect, useRef, useState } from 'react'
import { EditableIconStatus, EditableTextStyled } from './styled'
import { MdEdit, MdSave, MdErrorOutline } from 'react-icons/md'
import { Tooltip } from 'react-tooltip'

export interface TextInputProps {
  onNameChange: (newValue: string) => void
  value: string
  errorMessage?: string
  isEditable?: boolean
}

export const EditableText: React.FC<TextInputProps> = ({
  value,
  errorMessage,
  onNameChange,
  ...props
}) => {
  const [hasEditModeEnabled, setHasEditModeEnabled] = useState<boolean>(true)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const iconInputStatus = () => {
    if (hasEditModeEnabled) {
      return <MdEdit />
    } else {
      return <MdSave />
    }
  }

  const handleChangeInputStatus = () => {
    if (value) {
      setHasEditModeEnabled(!hasEditModeEnabled)
    }
  }

  // set focus on input once visibile
  useEffect(() => {
    if (!hasEditModeEnabled && inputRef.current) {
      inputRef.current.focus()
    }
  }, [hasEditModeEnabled])

  return (
    <EditableTextStyled>
      <EditableIconStatus>
        {errorMessage ? (
          <>
            <Tooltip id='topbar-input' place='bottom' variant={'error'}>
              <span>{errorMessage}</span>
            </Tooltip>
            <MdErrorOutline
              className={'error'}
              data-tooltip-id='topbar-input'
            />
          </>
        ) : null}
      </EditableIconStatus>
      <input
        ref={inputRef}
        style={{ width: (value.length + 3) * 8 + 'px' }}
        disabled={hasEditModeEnabled}
        value={value}
        title={value}
        onChange={(e) => onNameChange(e.target.value)}
      />

      {props.isEditable ? (
        <span
          className='text-input-icon'
          onClick={() => handleChangeInputStatus()}
        >
          {iconInputStatus()}
        </span>
      ) : (
        <></>
      )}
    </EditableTextStyled>
  )
}
