import React, { useState, useEffect, Fragment } from 'react'
import { useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import { BridgeFormValues, MappedField, MappedFieldFormula } from 'Nbee'

import { Button } from '@components/Basic/ButtonNbe'
import { Loader } from '@components/Basic/Loader'
import { Modal } from '@components/Basic/Modal'
import { InputField } from '@app/components/Form/InputField'
import {
  InputSmartSelect,
  SelectValue,
} from '@components/Form/InputSmartSelect'
import { InputSmartCreatableSelect } from '@app/components/Form/InputSmartSelect/Creatable'

import {
  FormulaModalBody,
  FormulaModalCloseIcon,
  FormulaModalFooter,
  FormulaModalHeader,
  FormulaModalHero,
  FormulaModalWrapper,
} from '@features/nbee/FieldsMappingForm/FormulaModal/styled'
import { IoCloseOutline } from 'react-icons/io5'

import { useGetSingleFormulaSchema } from '@app/api/getSingleFormulaSchema'

interface ModalProps {
  isOpen: boolean
  onCloseModal: () => void
  sourceFieldOptions?: SelectValue[] | []
  modalTitle: string
  index: number
  formulaId: string
}

export const FormulaModal: React.FC<ModalProps> = ({
  isOpen,
  onCloseModal,
  sourceFieldOptions,
  modalTitle,
  index,
  formulaId,
}) => {
  const { t } = useTranslation()

  // Extract information from Formik context
  const { values } = useFormikContext<BridgeFormValues>()

  const fieldName = `fieldsMapping.${index}`
  const [field, meta, helpers] = useField<MappedField>(fieldName)

  // Variables to simplify the code
  const currentFieldValue = field.value
  const currentFieldFormula = currentFieldValue?.formula
  const currentFieldFormulaParams = currentFieldFormula?.params

  // Create a temporary state to not save immediately in Formik context
  const [formulaTemporaryState, setFormulaTemporaryState] =
    useState<MappedFieldFormula>(currentFieldFormula as MappedFieldFormula)

  // Disable submit button if there are errors in the form
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    useState<boolean>(false)

  // Retrieve single formula schema, passing bridge ID and formula ID
  const { data: singleFormulaSchema, isLoading: isGettingSingleFormulaSchema } =
    useGetSingleFormulaSchema(`${values.ui?.bridgeId}`, formulaId)

  // Memoize formula name, formula description and param title to avoid re-rendering
  const formulaName = React.useMemo(
    () =>
      singleFormulaSchema && singleFormulaSchema.data.length
        ? `${singleFormulaSchema?.data[0]?.category} >> ${t(
            `nbee.formulas.${singleFormulaSchema?.data[0]?.id}.Name` as any
          )}`
        : undefined,
    [t, singleFormulaSchema]
  )
  const formulaDescription = React.useMemo(
    () =>
      singleFormulaSchema
        ? t(
            `nbee.formulas.${singleFormulaSchema?.data[0]?.id}.Description` as any
          )
        : undefined,
    [t, singleFormulaSchema]
  )
  const getParamDescription = React.useMemo(
    () => (paramId: string) =>
      t(
        `nbee.formulas.${singleFormulaSchema?.data[0]?.id}.Params.${paramId}` as any
      ),
    [t, singleFormulaSchema]
  )

  const singleFormulaSchemaParams = singleFormulaSchema?.data[0]?.params || []

  // if there are no params in the formik context it's because we've just changed the formula.
  // in that case, we need to load in the state the params of the new formula
  useEffect(() => {
    if (singleFormulaSchema && !currentFieldFormulaParams?.length) {
      setFormulaTemporaryState({
        ...(currentFieldFormula as MappedFieldFormula),
        params:
          singleFormulaSchema?.data[0]?.params?.map((p, index) => {
            return {
              ...p,
              required: p.type === 'field' && index === 0,
            }
          }) || [],
      })
    } else if (singleFormulaSchema && currentFieldFormulaParams?.length) {
      // otherwise, we need to update the state with the params of the formik context
      setFormulaTemporaryState({
        ...(currentFieldFormula as MappedFieldFormula),
        params:
          currentFieldFormulaParams?.map((p) => ({
            ...p,
            values: p.values,
            required: p.type === 'field' && index === 0,
          })) || [],
      })
    }
  }, [singleFormulaSchema])

  const getOptionsForSelectDropdown = (paramId: string) => {
    const selectedParam = singleFormulaSchema?.data[0]?.params?.find(
      (p) => p.id === paramId
    )
    if (Array.isArray(selectedParam?.values) && selectedParam?.values) {
      return selectedParam?.values.map(
        (v) => ({ value: v, label: v }) as SelectValue
      )
    } else {
      return []
    }
  }

  const getDefaultValueForSelect = (paramId: string) => {
    if (!formulaTemporaryState.params || !sourceFieldOptions) {
      return undefined
    }
    const selectedParam = formulaTemporaryState.params.find(
      (p) => p.id === paramId
    )
    if (!selectedParam) {
      return undefined
    }
    if (selectedParam.type === 'field') {
      return sourceFieldOptions.find(
        (option) => option.value === selectedParam.values
      )
    }
    if (selectedParam.type === 'dropdown') {
      return getOptionsForSelectDropdown(paramId)?.find(
        (option) => option.value === selectedParam.values
      )
    }
  }

  const getDefaultValueForCreatableSelect = (paramId: string) => {
    if (!formulaTemporaryState?.params?.length) {
      return undefined
    }
    const selectedParam = formulaTemporaryState.params.find(
      (p) => p.id === paramId
    )
    return (selectedParam?.values as string[]) || []
  }

  const handleValueChange = (
    paramId: string,
    value: string | string[] | number
  ) => {
    if (!formulaTemporaryState) {
      return undefined
    }
    const updatedFormula: MappedFieldFormula = {
      id: formulaTemporaryState.id,
      params: [...formulaTemporaryState.params] || [],
    }
    const paramIndex = updatedFormula.params.findIndex((p) => p.id === paramId)
    if (paramIndex >= 0) {
      updatedFormula.params[paramIndex].values = value as string | string[]
    }

    // Handle field value change and update state, not formik context
    setFormulaTemporaryState(updatedFormula)
  }

  const transformFieldId = (paramId?: string): string | null => {
    if (!paramId) return null

    const transformedId = paramId
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/field\.(\d+)/gi, (_, num) => `Field ${Number(num) + 1}`)
      .replace(/\b(\w)/g, (char) => char.toUpperCase())

    return transformedId
  }

  // Handle submit button disabled state
  useEffect(() => {
    if (isGettingSingleFormulaSchema) {
      setIsSubmitButtonDisabled(true)
    }
    if (!formulaTemporaryState.params[0]?.values) {
      setIsSubmitButtonDisabled(true)
    } else {
      setIsSubmitButtonDisabled(false)
    }
  }, [formulaTemporaryState])

  const handleAbort = () => {
    if (!currentFieldFormula?.params[0]?.values) {
      // if the initial formula state has no field value, we need to reset the formula to the default state
      helpers.setValue({
        ...currentFieldValue,
        formula: { id: '', params: [] },
      })
    }
    helpers.setTouched(true) // forces validation to run
    onCloseModal()
  }
  const handleSubmit = () => {
    helpers.setValue({
      ...currentFieldValue,
      formula: formulaTemporaryState,
    })
    helpers.setTouched(true) // forces validation to run
    onCloseModal()
  }

  return (
    <Modal noPadding isOpen={isOpen} onCloseModal={onCloseModal}>
      {isGettingSingleFormulaSchema && (
        <Loader $active $dimmer $text='Populating fields...' />
      )}

      <FormulaModalWrapper>
        <FormulaModalHeader>
          <h3>
            {modalTitle} {(formulaName && formulaName.toUpperCase()) || ''}
          </h3>
          <FormulaModalCloseIcon onClick={handleAbort}>
            <IoCloseOutline />
          </FormulaModalCloseIcon>
        </FormulaModalHeader>

        <FormulaModalHero>
          <small
            dangerouslySetInnerHTML={{
              // substitutes the space after a period with a <br/> tag, probably unnecessary
              __html:
                (formulaDescription &&
                  formulaDescription.replace(
                    /(\.)(\s)(?=[A-Z])/g,
                    '$1<br/>'
                  )) ||
                '',
            }}
          />
        </FormulaModalHero>

        <FormulaModalBody>
          {singleFormulaSchemaParams &&
            singleFormulaSchemaParams.map((param) => (
              <Fragment key={`${param.id}-${isOpen ? 'open' : 'closed'}`}>
                <div style={{ marginTop: '.6rem' }}>
                  <h5>
                    {transformFieldId(param?.id)}
                    {/* Adds red star if param is required */}
                    {/* {param.type === 'field' ? (
                    <>
                      <span style={{ color: 'red' }}>{'*'}</span>
                      {':'}
                    </>
                  ) : (
                    <>{':'}</>
                  )} */}
                  </h5>
                  <small>{getParamDescription(param.id)}</small>
                </div>
                <div style={{ margin: '.6rem 0' }}>
                  {param.type === 'field' ? (
                    <InputSmartSelect
                      initialValues={sourceFieldOptions || []}
                      defaultValue={getDefaultValueForSelect(param.id)}
                      onSelect={(selectedValue) => {
                        const singleValue = selectedValue as SelectValue
                        if (singleValue) {
                          handleValueChange(param.id, singleValue.value)
                        }
                      }}
                      upDownIconsStyle
                      isClearable
                    />
                  ) : param.type === 'dropdown' ? (
                    <InputSmartSelect
                      initialValues={getOptionsForSelectDropdown(param.id)}
                      defaultValue={getDefaultValueForSelect(param.id)}
                      onSelect={(selectedValue) => {
                        const singleValue = selectedValue as SelectValue
                        if (singleValue) {
                          handleValueChange(param.id, singleValue.value)
                        }
                      }}
                      upDownIconsStyle
                      isClearable
                    />
                  ) : param.type === 'text' ||
                    param.type === 'char' ||
                    param.type === 'int' ? (
                    <InputField
                      name={`${param.id}`}
                      type={'text'}
                      maxLength={param.type === 'char' ? 1 : undefined}
                      value={
                        (formulaTemporaryState &&
                          formulaTemporaryState.params.find(
                            (p) => p.id === param.id
                          )?.values) ||
                        ''
                      }
                      onChange={(e) =>
                        handleValueChange(param.id, e.target.value)
                      }
                    />
                  ) : param.type === 'tags' ? (
                    <InputSmartCreatableSelect
                      name={`${param.id}`}
                      placeholder={''}
                      defaultValue={getDefaultValueForCreatableSelect(param.id)}
                      onSelect={(newValues) =>
                        handleValueChange(param.id, newValues)
                      }
                      isClearable={false}
                    />
                  ) : null}
                </div>
              </Fragment>
            ))}
        </FormulaModalBody>

        <FormulaModalFooter>
          <Button type={'button'} $variant={'secondary'} onClick={handleAbort}>
            {'Abort'}
          </Button>
          <Button
            type={'button'}
            $variant={'primary'}
            onClick={handleSubmit}
            disabled={isSubmitButtonDisabled}
          >
            {'Confirm'}
          </Button>
        </FormulaModalFooter>
      </FormulaModalWrapper>
    </Modal>
  )
}
