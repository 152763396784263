import React, { lazy, Suspense } from 'react'
import { useGetUserInfo } from '@app/api/getUserInfo'
import { Route, Routes } from 'react-router-dom'
import { appRoutes } from '@app/routes'
import { useIntercom } from 'react-use-intercom'

const InfoBarComponentLazy = lazy(() => import('./InfoBarComponent'))

const SuperUserInfoBarWithQuery: React.VFC = () => {
  const { data: userData } = useGetUserInfo()
  const { boot } = useIntercom()

  if (userData?.user && userData?.superUser?.email) {
    return (
      <Suspense fallback={null}>
        <InfoBarComponentLazy
          fullName={`${userData.user.firstName} ${userData.user.lastName}`}
          email={userData.user.email || ''}
        />
      </Suspense>
    )
  }

  if (userData?.user) {
    const intercomUserId = userData.user.slaveUserId
      ? 'SUID-' + userData.user.slaveUserId
      : 'UID-' + userData.user.userId
    const intercomData = {
      name: `${userData.user.firstName} ${userData.user.lastName}`,
      email: `${userData.user.email}`,
      customAttributes: {
        user_id: `${intercomUserId}`,
      },
    }
    boot(intercomData)
  }

  return null
}

// In case we are in logout path we can't load the SuperUserInfoBarWithQuery
// because it will call `useGetUserInfo` (user/me) api that will generate an endless loop
// `user/me` returns 401 -> redirect to logout -> calls user/me again -> 401 -> new redirection
export const SuperUserInfoBar: React.VFC = () => {
  return (
    <Routes>
      <Route path={appRoutes.logout.path} element={<div />} />
      <Route path='/*' element={<SuperUserInfoBarWithQuery />} />
    </Routes>
  )
}
