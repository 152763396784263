import React from 'react'
import { useGetBridgeById } from '@app/api/getBridgeById'
import { Loader } from '@components/Basic/Loader'
import { Formik, useFormikContext } from 'formik'
import { BridgeFormValues, EmailReceiptSettings } from 'Nbee'
import { emailReceiptValidationSchema } from '@features/nbee/EmailReceipt/validation'
import { FormInner } from '@features/nbee/EmailReceipt/FormInner'
import { FeatureNotAvailable } from '@features/nbee/EmailReceipt/FeatureNotAvailable'
import { useGetUserModules } from '@app/api/getUserModules'
import { useGetUserInfo } from '@app/api/getUserInfo'
import { minimumPlanName } from '@features/nbee/FieldsMappingForm'
import { StarterLabel } from '@features/nbee/AddFilterButton/styled'
import { FaLock } from 'react-icons/fa'
import { EmailReceiptStyled } from '@features/nbee/EmailReceipt/styled'
import { useUpdateBridgeToPricing } from '@features/nbee/utils'

interface Props {
  bridgeId?: string | number
}

export const EmailReceipt: React.VFC<Props> = ({ bridgeId }) => {
  const { values, setValues, isValid } = useFormikContext<BridgeFormValues>()

  const isBridgeEnabled = values?.ui?.isBridgeEnabled
  const { TitleWrapper, IconLockWrapper } = EmailReceiptStyled

  const {
    data: bridgeFormValues,
    isLoading: isLoadingBridge,
    error: apiGetBridgeError,
  } = useGetBridgeById(bridgeId ? `${bridgeId}` : '')

  const {
    data: userModulesResponse,
    isLoading: isLoadingUserModules,
    error: apiUserModulesError,
  } = useGetUserModules()

  const userModules = userModulesResponse?.modules || []
  const emailReceiptUserModule = userModules.find(
    (m) => m.id === 'email-receipt'
  )
  const moduleReceiptId = emailReceiptUserModule?.id

  const {
    data: userData,
    isLoading: isLoadingUser,
    refetch: refetchUserInfo,
  } = useGetUserInfo()

  const userEmail = userData?.user.email ? [userData?.user.email] : []

  const isLoadingAll = isLoadingBridge || isLoadingUserModules
  const isAvailableToUser = Boolean(emailReceiptUserModule?.enabled)
  const minimumPlanRequired = emailReceiptUserModule?.minimumPlan
  const apiError = apiGetBridgeError || apiUserModulesError

  const updateBridgeToPricing = useUpdateBridgeToPricing(
    bridgeId,
    moduleReceiptId,
    minimumPlanRequired
  )

  const planLabelName = minimumPlanRequired
    ? minimumPlanName[minimumPlanRequired.toString()].toUpperCase()
    : ''

  const settings = bridgeFormValues?.settings
  const emailReceiptRecipients = settings?.emailReceipt?.recipients || []
  const userDefaultRecipients =
    emailReceiptUserModule?.userDefaults.recipients || []

  const recipients =
    emailReceiptRecipients.length > 0
      ? emailReceiptRecipients
      : userDefaultRecipients.length > 0
      ? userDefaultRecipients
      : userEmail

  const initialValues: EmailReceiptSettings = {
    active:
      settings?.emailReceipt?.active ?? values.settings?.emailReceipt?.canShow
        ? emailReceiptUserModule?.userDefaults.active ??
          settings?.emailReceipt?.active ??
          false
        : false,
    recipients:
      settings?.emailReceipt?.active ?? values.settings?.emailReceipt?.canShow
        ? recipients
        : [],
    subject:
      settings?.emailReceipt?.active ?? values.settings?.emailReceipt?.canShow
        ? settings?.emailReceipt?.subject ||
          emailReceiptUserModule?.userDefaults.subject ||
          'New Lead'
        : '',
    canShow: values.settings?.emailReceipt?.canShow ?? false, // canShow value is in Formik context due to useHandleAppsCompatibility hook result
  }

  const handleSaveSettings = (emailReceiptValues: EmailReceiptSettings) => {
    setValues({
      ...values,
      settings: {
        emailReceipt: {
          ...values.settings?.emailReceipt,
          ...emailReceiptValues,
        },
      },
    })
  }

  const isEmailReceiptCompatible = values?.settings?.emailReceipt?.canShow

  const canShowEmailReceipt =
    initialValues && isEmailReceiptCompatible && isValid

  return isLoadingAll ? (
    <div>
      <Loader $active $dimmer />
    </div>
  ) : apiError ? null : canShowEmailReceipt ? (
    <div style={{ marginTop: '3rem' }}>
      <TitleWrapper>
        <h2 style={{ margin: '0 1rem 1rem 0' }}>Email Receipt</h2>
        <IconLockWrapper>
          {!isAvailableToUser && (
            <StarterLabel small onClick={() => updateBridgeToPricing()}>
              <FaLock /> {planLabelName}
            </StarterLabel>
          )}
        </IconLockWrapper>
      </TitleWrapper>

      {isAvailableToUser ? (
        <Formik
          initialValues={initialValues}
          validationSchema={emailReceiptValidationSchema}
          onSubmit={() => {}}
        >
          <FormInner
            isBridgeEnabled={isBridgeEnabled}
            handleSubmit={handleSaveSettings}
          />
        </Formik>
      ) : (
        <FeatureNotAvailable
          moduleReceiptId={moduleReceiptId}
          requiredLbPlanId={minimumPlanRequired}
          bridgeId={bridgeId}
        />
      )}
    </div>
  ) : null
}
