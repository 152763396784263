import React from 'react'
import { SimplePage } from '@app/layouts/SimplePage'
import styled from 'styled-components'
import superHeroImg from '@assets/images/superhero.svg'
import { Button } from '@components/Basic/ButtonNbe'
import { useLocation } from 'react-router-dom'

export const WrapperStyled = styled.div`
  height: 100%;
  margin: 12rem auto;
  text-align: center;

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: #273649;
  }

  img {
    margin: 2rem 0;
    width: 16rem;
  }

  p {
    font-size: 0.875rem;
    font-weight: 700;
    margin: 0;
  }

  button {
    margin-top: 2rem;
    width: 15rem;
  }
`

export type ThankYouPageState = {
  title: string
  description: string[]
  ctaText: string
}

export const ThankYouPage: React.FC = () => {
  const state = useLocation().state as ThankYouPageState

  // we set some defaults in case state is missing
  const title = state?.title || 'Thank you'
  const description = state?.description || ['Your request has been processed.']
  const ctaText = state?.ctaText || 'Continue'

  return (
    <SimplePage>
      <WrapperStyled>
        <h2>{title}</h2>
        <img src={superHeroImg} alt={'super hero'} />
        {description.map((row, idx) => (
          <p key={idx}>{row}</p>
        ))}
        <Button
          $size={'large'}
          $variant={'action'}
          onClick={() => {
            window.location.href = process.env.REACT_APP_V1_URL || ''
          }}
        >
          {ctaText}
        </Button>
      </WrapperStyled>
    </SimplePage>
  )
}
