import React from 'react'
import { MessageStyled } from './styled'

export interface MessageProps {
  $status?: 'success' | 'error'
  $header?: string
  children?: React.ReactNode
}

export const Message: React.FC<MessageProps> = ({
  $header,
  children,
  ...props
}) => {
  return (
    <MessageStyled $header={$header} {...props}>
      {$header && <div className={'message-title'}>{$header}</div>}
      <div className={'message-body'}>{children}</div>
    </MessageStyled>
  )
}
