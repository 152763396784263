import React, { ReactNode } from 'react'
import { ReactComponent as BackSVG } from '@assets/images/chevron-left.svg'
import { TopbarStyled, TopbarWrapper } from './styled'
import { EditableText } from '@components/Basic/EditableText'
import { Link } from 'react-router-dom'

export interface TopbarBacklinkProps {
  url: string
  label: string
}

export interface TopbarProps {
  defaultValue?: string
  errorMessage?: string
  onNameChange?: (newValue: string) => void // owner : page/layout
  backlink?: TopbarBacklinkProps
  hideOnEmbeddable?: boolean
  variant?: 'light' | 'dark'
  subBar?: React.ReactNode
  children?: ReactNode
}

export const Topbar: React.FC<TopbarProps> = ({
  defaultValue,
  errorMessage,
  onNameChange,
  backlink,
  children,
  hideOnEmbeddable,
  variant,
}) => {
  return (
    <TopbarStyled>
      <TopbarWrapper
        hideOnEmbeddable={hideOnEmbeddable}
        variant={variant || 'light'}
      >
        <div>
          {backlink ? (
            <Link to={backlink.url} data-testid='exit_btn'>
              <BackSVG /> {backlink.label}
            </Link>
          ) : (
            ''
          )}
        </div>
        <div>
          <EditableText
            onNameChange={(newValue) => {
              if (onNameChange) {
                onNameChange(newValue)
              }
            }}
            errorMessage={errorMessage}
            value={defaultValue || ''}
            isEditable={Boolean(onNameChange)}
          />
        </div>
        <div>{children}</div>
      </TopbarWrapper>
    </TopbarStyled>
  )
}
