import React, { createContext, useEffect, useRef, useState } from 'react'
import {
  PanelBody,
  PanelBodyInner,
  PanelFooter,
  PanelFooterContainer,
  PanelFooterLeft,
  PanelFooterRight,
  PanelFooterWrapper,
  PanelHead,
  PanelStyled,
  PanelWrapper,
} from './styled'
import { PanelPopup, PanelPopupProps } from '@components/PanelPopup'
import { ProgressBar } from '@components/Panel/ProgressBar'

interface PanelPopupContext {
  popup?: PanelPopupProps
  sendPopup?: (popup?: PanelPopupProps) => void
}

export const PanelPopupContext = createContext<PanelPopupContext>({})

interface PanelProps {
  head?: React.ReactNode
  body?: React.ReactNode
  progressBarPercentage?: number
  footer?: {
    left?: React.ReactNode
    right?: React.ReactNode
  } | null
}

export const Panel: React.FC<PanelProps> = ({
  head,
  body,
  footer,
  progressBarPercentage,
}) => {
  const [popup, setPopup] = useState<PanelPopupProps | undefined>()
  const [bodyPaddingBottom, setBodyPaddingBottom] = useState<number>()

  const bodyOuterRef = useRef<HTMLDivElement | null>(null)
  const bodyInnerRef = useRef<HTMLDivElement | null>(null)
  const footerRef = useRef<HTMLDivElement | null>(null)

  // when bodyInner has scroll, we set a top seprator for footer
  const bodyOuterHeight = bodyOuterRef.current?.clientHeight
  const bodyInnerHeight = bodyInnerRef.current?.clientHeight
  const footerHasTopSeparator =
    bodyOuterHeight && bodyInnerHeight
      ? bodyInnerHeight > bodyOuterHeight
      : false

  // To avoid that footer will overlaps body content we set a padding bottom equal to footer height
  useEffect(() => {
    if (footerRef.current) {
      setBodyPaddingBottom(footerRef.current?.clientHeight)
    }
  }, [footerRef])

  return (
    <PanelPopupContext.Provider
      value={{
        popup: popup,
        sendPopup: setPopup,
      }}
    >
      <PanelStyled extraPadding={Boolean(footer)}>
        <PanelWrapper>
          {progressBarPercentage ? (
            <ProgressBar percentage={progressBarPercentage} />
          ) : null}
          {head ? (
            <PanelHead>{head}</PanelHead>
          ) : (
            // in case of no head we keep some top space
            <div style={{ paddingTop: '1rem' }} />
          )}
          {body || footer ? (
            <PanelBody
              $hasExtraTopPadding={!head}
              ref={bodyOuterRef}
              $panelHasHead={!!head}
            >
              <PanelBodyInner
                ref={bodyInnerRef}
                paddingBottom={footer ? bodyPaddingBottom : 0}
              >
                {body}
              </PanelBodyInner>
            </PanelBody>
          ) : null}
        </PanelWrapper>
      </PanelStyled>
      {/* footer */}
      {footer ? (
        <PanelFooter ref={footerRef}>
          <PanelFooterContainer $showTopSeparator={footerHasTopSeparator}>
            {popup ? (
              <PanelPopup
                {...popup}
                onClose={() => {
                  setPopup(undefined)
                  if (popup.onClose) {
                    popup.onClose()
                  }
                }}
              />
            ) : null}

            <PanelFooterWrapper>
              <PanelFooterLeft>{footer.left}</PanelFooterLeft>
              <PanelFooterRight>{footer.right}</PanelFooterRight>
            </PanelFooterWrapper>
          </PanelFooterContainer>
        </PanelFooter>
      ) : null}
    </PanelPopupContext.Provider>
  )
}
