import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import {
  BillingFormValues,
  statesValidationSchema,
  validationSchema,
} from '@features/billing/BillingBox/formConfig'
import { FormikInputField } from '@components/Formik/FormikInputField'
import { FormikInputCheckbox } from '@components/Formik/FormikInputCheckbox'
import { FormikInputSelect } from '@components/Formik/FormikInputSelect'
import { SyncValuesWithParent } from '@features/billing/BillingBox/SyncValuesWithParent'
import { BillingDetails } from '@features/billing/BillingBox/BillingDetails'

import {
  CustomInputField,
  FormInputGroup,
  WrapperInputCheckbox,
  Wrapper,
  InputWrapper,
} from './styled'
import { useGetCountries } from '@app/api/getCountries'
import { useGetStates } from '@app/api/getStates'
import { ApiUserInfo } from 'BackendApi'
import { FormikInputSmartSelect } from '@components/Formik/FormikInputSmartSelect'

interface Props {
  initialFormValues: BillingFormValues
  onValuesChange: (newValues: BillingFormValues, isValid: boolean) => void
  isDataEmpty: boolean
  userInfo: ApiUserInfo
  onStripeSubmit: boolean
}

export const BillingBox: React.FC<Props> = ({
  initialFormValues,
  onValuesChange,
  isDataEmpty,
  userInfo,
  onStripeSubmit,
}) => {
  const [isAddingNewInfo, setIsAddingNewInfo] = useState(false)
  const [countryCode, setCountryCode] = useState(initialFormValues.country)
  const [noCompany, setNoCompany] = useState(initialFormValues.noCompany)
  const [validation, setValidation] = useState(validationSchema)

  const handleValues = (newValues: BillingFormValues) => {
    setNoCompany(newValues.noCompany)
    setCountryCode(newValues.country)
  }

  const {
    data: countries,
    isLoading: isLoadingCountries,
    error: countriesError,
  } = useGetCountries()

  const {
    data: states,
    refetch: refetchStates,
    isLoading: isLoadingStates,
    error: statesError,
    remove: removeData,
  } = useGetStates(countryCode || ' ')

  useEffect(() => {
    if (isDataEmpty) {
      setIsAddingNewInfo(true)
    }
  }, [isDataEmpty])

  useEffect(() => {
    refetchStates()
  }, [countryCode])

  return (
    <Wrapper>
      {isAddingNewInfo ? (
        <Formik
          initialValues={initialFormValues}
          validationSchema={validation}
          validateOnChange
          validateOnBlur
          onSubmit={async () => {
            // No submit needed , we pass sync values to parent
          }}
        >
          {(formikProps) => {
            const isCountryStates = formikProps.values.country === 'US'

            return (
              <Form>
                <SyncValuesWithParent
                  onStripeSubmit={onStripeSubmit}
                  onValuesChange={(newValues, isValid) => {
                    if (newValues.noCompany) {
                      formikProps.setFieldValue('companyName', '')
                    }
                    if (newValues.country === 'US') {
                      setValidation(statesValidationSchema)
                    } else {
                      setValidation(validationSchema)
                      formikProps.setFieldValue('state', '')
                    }
                    handleValues(newValues)
                    onValuesChange(newValues, isValid)
                  }}
                />
                <CustomInputField
                  name={'companyName'}
                  type={'text'}
                  label={'Company Name'}
                  disabled={noCompany}
                />
                <WrapperInputCheckbox>
                  <FormikInputCheckbox name={'noCompany'}>
                    {"I'm not a company"}
                  </FormikInputCheckbox>
                </WrapperInputCheckbox>
                <FormInputGroup>
                  <InputWrapper>
                    <FormikInputSmartSelect
                      initialValues={
                        (countries || []).map((item) => ({
                          value: item.id,
                          label: item.text,
                        })) || []
                      }
                      name={'country'}
                      label={'Country'}
                      secondaryStyles
                      noIcon
                      isClearable={false}
                    />
                  </InputWrapper>
                  {isCountryStates ? (
                    <InputWrapper>
                      <FormikInputSelect
                        $optionsLabel={
                          !states?.length || !isCountryStates
                            ? undefined
                            : 'State'
                        }
                        name={'state'}
                        $options={
                          !isCountryStates
                            ? []
                            : (states || []).map((item) => ({
                                value: item.id,
                                label: item.id,
                              }))
                        }
                        $label={'State'}
                        style={{ backgroundColor: '#ededee' }}
                        disabled={!states?.length || !isCountryStates}
                      />
                    </InputWrapper>
                  ) : null}

                  <InputWrapper>
                    <FormikInputField
                      type={'text'}
                      name={'zip'}
                      label={'Zip'}
                      style={{ backgroundColor: '#ededee' }}
                    />
                  </InputWrapper>
                </FormInputGroup>
              </Form>
            )
          }}
        </Formik>
      ) : (
        <BillingDetails
          billingInfo={initialFormValues}
          onEditRequest={() => setIsAddingNewInfo(true)}
          userInfo={userInfo}
        />
      )}
    </Wrapper>
  )
}
