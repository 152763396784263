import React, { useEffect, useRef, useState } from 'react'
import { FacebookButtonCustom } from '@components/ButtonProvider/Facebook/FacebookButtonCustom'
import { FacebookButtonNative } from '@components/ButtonProvider/Facebook/FacebookButtonNative'
import { GoogleButtonNative } from '@components/ButtonProvider/Google/GoogleButtonNative'
import { GoogleButtonCustom } from '@components/ButtonProvider/Google/GoogleButtonCustom'
import { apiPublicClient } from '@app/services/apiPublicClient'
import { endpoints } from '@app/api/config'
import { parseApiError } from '@app/api/utils/error'
import AuthService from '@app/services/AuthService'
import { appRoutes, bridgeByUrlPartnersPaths } from '@app/routes'
import { deletePersistendSignupParms } from '@features/signup/SignupForm/useHideParamsFromString'
import { matchPath, useLocation } from 'react-router-dom'
import { ApiErrorResponse, ApiUser, ApiUserResponse } from 'BackendApi'
import { trackEvent } from '@app/dataTracking'
import { ButtonErrorUi } from '@components/ButtonProvider/Facebook/ButtonErrorUi'
import { GetCaptchaToken } from '@components/ReCaptcha'
import { signInUserSessionCognito } from '@app/services/apiAuthClient/utils'
import { getAffiliateIdFromCookies } from '@app/utils/cookieUtils'

export type SignupProviderName = 'Facebook' | 'Google'
export type AuthScope = 'SignIn' | 'SignUp' | 'NoScope'
type SignUpParams = {
  trialDays: number
  cbPlan: string
  couponCode?: string
}

export interface ButtonProviderProps {
  scope: AuthScope
  provider: SignupProviderName
  signupParams?: SignUpParams
  onBeforeAuth?: () => void
  onAuthError?: (errorMessage: string, errorCode?: string | number) => void
  onAuthSuccess?: (apiResponse: ApiUser) => void
  redirectPath?: string
  dataWidth?: number
  getCaptchaToken?: GetCaptchaToken
}

export const ButtonProvider: React.FC<ButtonProviderProps> = ({
  provider,
  scope,
  onBeforeAuth,
  onAuthSuccess,
  onAuthError,
  dataWidth,
  signupParams,
  getCaptchaToken,
}) => {
  const location = useLocation()

  const paths = [appRoutes.signupBbu.path, ...bridgeByUrlPartnersPaths]

  const matchBBuPath = paths.find((path) =>
    matchPath({ path: path }, location.pathname)
  )

  const bbuUri = matchBBuPath
    ? `${window.location.origin}${location.pathname}`
    : undefined

  const [isGoogleButtonCustomLoaded, setIsGoogleButtonCustomLoaded] =
    useState<boolean>(false)
  const [googleButtonToUseForSignup, setGoogleButtonToUseForSignup] = useState<
    'native' | 'custom'
  >('native')
  const [sdkFailedToLoad, setSdkFailedToLoad] = useState(false)

  const canUpdateState = useRef(true)
  useEffect(() => {
    canUpdateState.current = true
    return () => {
      if (canUpdateState) {
        canUpdateState.current = false
      }
    }
  })
  const handleSdkLoadError = () => {
    if (canUpdateState.current) {
      setSdkFailedToLoad(true)
    }
  }

  const handleExternalProviderAuth = async (oauthToken: string) => {
    if (onBeforeAuth) {
      onBeforeAuth()
    }

    let recaptchaToken: string | undefined
    if (getCaptchaToken) {
      recaptchaToken = await getCaptchaToken()
    }

    const tapExistingCookieData = getAffiliateIdFromCookies()

    console.log(
      'calling authWithExternalProvider',
      'tapVisitorId',
      tapExistingCookieData?.tapVisitorId,
      'tapClickId',
      tapExistingCookieData?.tapClickId
    )

    const authResponse = await apiPublicClient
      .post<ApiUserResponse>(endpoints.authWithExternalProvider, {
        scope: scope,
        bbuUri: bbuUri,
        referralUri: document.referrer || undefined,
        oauthToken: oauthToken,
        provider: provider,
        recaptchaToken: recaptchaToken || '',
        tapClickId: tapExistingCookieData?.tapClickId || undefined,
        tapVisitorId: tapExistingCookieData?.tapVisitorId || undefined,
        // signup params if any
        ...signupParams,
      })
      .catch((error: ApiErrorResponse) => {
        const parsedError = parseApiError(error)

        if (onAuthError) {
          onAuthError(parsedError.message, parsedError.code)
        }

        trackEvent({
          eventName: 'UnexpectedErrorThrown',
          feature: 'Authentication',
          // detecting scope, or not passing step when scope is 'NoScope' (eg. bbu)
          step:
            scope === 'SignIn'
              ? 'Signin'
              : scope === 'SignUp'
              ? 'Signup'
              : undefined,
          params: {
            custom: {
              errorCode: parsedError.code,
              errorDescription: parsedError.message,
              provider: provider,
              scope: scope,
              planId: signupParams?.cbPlan,
            },
          },
        })
      })

    if (!authResponse) {
      return
    }

    const userInfo = authResponse.data?.data
    const userCredentials = userInfo?.credentials

    if (
      !userCredentials ||
      !userCredentials.username ||
      !userCredentials.idToken ||
      !userCredentials.accessToken ||
      !userCredentials.refreshToken
    ) {
      if (onAuthError) {
        console.error('missing auth response', authResponse)
      }
      return
    }

    signInUserSessionCognito({
      username: userCredentials.username,
      idToken: userCredentials.idToken,
      accessToken: userCredentials.accessToken,
      refreshToken: userCredentials.refreshToken,
    })

    if (scope === 'SignUp') {
      // on signup we handle a redirect
      try {
        await AuthService.setSessionCookie()
      } catch (error) {
        console.error(error instanceof Error ? error.message : 'Unknown error')
      }
    } else {
      // on signin or no scope we also update the UI_AUTH_CHANNEL (Amplify Auth provider)
      await AuthService.onLoginAsync(false)
    }

    if (onAuthSuccess && userInfo) {
      onAuthSuccess(userInfo)
    }

    deletePersistendSignupParms()
  }

  const providerRender = () => {
    switch (provider) {
      case 'Facebook':
        // we don't show button fb is signup is sdk fails to load, in other pages (bbu) we show the ButtonErrorUi
        return sdkFailedToLoad ? (
          <ButtonErrorUi scope={scope} />
        ) : scope === 'SignUp' ? (
          <FacebookButtonCustom
            onTokenRetrived={handleExternalProviderAuth}
            scope={scope}
            onSdkLoadError={handleSdkLoadError}
          />
        ) : (
          <FacebookButtonNative
            onTokenRetrived={handleExternalProviderAuth}
            scope={scope}
            dataWidth={dataWidth}
            onSdkLoadError={handleSdkLoadError}
          />
        )
      case 'Google':
        if (scope === 'SignUp' && googleButtonToUseForSignup === 'custom') {
          return (
            <div style={{ opacity: isGoogleButtonCustomLoaded ? 1 : 0 }}>
              <GoogleButtonCustom
                onTokenRetrived={handleExternalProviderAuth}
                scope={scope}
                onGoogleLegacySdkInitSuccess={() => {
                  // we know that legacy sdk has success:
                  // If we have a success state, we can make the button visible otherwise, we make the native key load.
                  // NB: The user will see the loading of a single button!
                  setIsGoogleButtonCustomLoaded(true)
                }}
                onGoogleLegacySdkInitError={() => {
                  // we know that legacy sdk has fail to laod
                  setGoogleButtonToUseForSignup('native')
                }}
              />
            </div>
          )
        } else {
          return (
            <GoogleButtonNative
              onTokenRetrived={handleExternalProviderAuth}
              scope={scope}
            />
          )
        }
    }
  }

  return <>{providerRender()}</>
}
