import React, { ReactNode, useEffect, useRef } from 'react'
import {
  makePopupFeatures,
  postInitialDataToPopup,
  usePopupMessageReceiver,
} from '@components/ConnectedPopupWindow/utils'

interface AuthorizationPopupProps {
  urlToOpen: string
  widthPx?: number
  heightPx?: number
  onPopupOpen: (popup: Window) => void
  onPopupClose: (newData?: unknown) => void
  popupName?: string
  initialData?: unknown
  skipOriginCheck?: boolean // must be true if is embedded in an inframe (eg: storybook)
  children?: ReactNode
}

export const ConnectedPopupWindow: React.FC<AuthorizationPopupProps> = ({
  urlToOpen,
  widthPx = 700,
  heightPx = 550,
  onPopupOpen,
  onPopupClose,
  initialData,
  skipOriginCheck,
  popupName = 'authPopup',
}) => {
  const popup = useRef<Window | null>(null)
  const isClosedRef = useRef<boolean>(false)
  const intervalId = useRef<number | null>()

  const { dataFromPopup } = usePopupMessageReceiver(popup)

  // Handle opening on new popup and posting of initialData
  useEffect(() => {
    // opening a new window as popup
    const features = makePopupFeatures(widthPx, heightPx)
    popup.current = window.open(urlToOpen, popupName, features)
    const currentPopup = popup.current

    if (!currentPopup) {
      onPopupClose()
      return
    }

    //  we send the instance of the just opened popup as callback
    onPopupOpen(currentPopup)
    currentPopup.focus()

    // send initial data to popup
    postInitialDataToPopup({
      initialData: initialData,
      popup: currentPopup,
      skipOriginCheck: skipOriginCheck,
    })

    // cleanup functions
    return () => {
      // In case our component in un-mounted, we want to automatically close our popup
      if (popup.current) {
        popup.current.close()
        onPopupClose()
      }
    }
  }, [])

  // constantly check if popup is closed, to sync outer state
  useEffect(() => {
    const currentPopup = popup.current
    if (currentPopup) {
      intervalId.current = window.setInterval(() => {
        if (currentPopup.closed && !isClosedRef.current) {
          isClosedRef.current = true
          onPopupClose()
        }
      }, 500)
    }

    return () => {
      if (intervalId.current !== null) {
        window.clearInterval(intervalId.current)
      }
    }
  }, [popup])

  // controlling the receival of data from popup
  useEffect(() => {
    if (dataFromPopup) {
      console.log('received new data from popup: ', dataFromPopup)
      onPopupClose(dataFromPopup)
      popup.current!.close()
    }
  }, [dataFromPopup])

  return null
}
