import React from 'react'
import styled from 'styled-components'
import { Label } from '@components/Form/Label'
import { Tooltip } from 'react-tooltip'
import { IoIosHelpCircleOutline } from 'react-icons/io'
import { useTranslation } from 'react-i18next'

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 0.5rem;
    cursor: pointer;
  }
`

const DocUrl = styled.div`
  margin-left: auto;
  font-size: 0.8rem; /* same as label */
  text-align: right;
`

interface Props {
  label: string
  docUrl?: string
  isRequired?: boolean
  htmlFor?: string
  tooltip?: string
}

export const LabelWithDocTooltip: React.FC<Props> = ({
  label,
  docUrl,
  isRequired,
  htmlFor,
  tooltip,
}) => {
  const { t } = useTranslation()
  const labelWithRequired = isRequired ? `${label} <strong>(*)</strong>` : label

  return (
    <LabelWrapper>
      <div>
        <Label htmlFor={htmlFor} $label={labelWithRequired} allowsHtml />
        {tooltip ? (
          <>
            <IoIosHelpCircleOutline
              size={'0.9em'}
              data-tooltip-content={tooltip}
              data-tooltip-id={'label'}
            />
            <Tooltip
              id={'label'}
              place={'bottom'}
              className={'reactTooltipSmall'}
            />
          </>
        ) : null}
      </div>
      {docUrl ? (
        <DocUrl>
          <a href={docUrl} target={'_blank'} rel={'noopener noreferrer'}>
            {t('nbee.bridgeBuilder.settingDocUrlText')}
          </a>
        </DocUrl>
      ) : null}
    </LabelWrapper>
  )
}
