import React, { ReactNode, useState } from 'react'
import {
  DropdownMenuDirection,
  DropdownMenuStyled,
  DropdownWrapper,
} from './styled'
import { KebabButton } from '@components/Dropdown/KebabButton'
import { useClickAway } from '@components/Dropdown/useClickAway'
type DropdownLineSeparatorChild = 'last' | 'none'

interface DropdownProps {
  $direction?: DropdownMenuDirection
  dropdownButton?: React.ReactNode
  $lineSeparatorMenuItem?: DropdownLineSeparatorChild
  children?: ReactNode
}

export const Dropdown: React.FC<DropdownProps> = ({
  $direction = 'left',
  children,
  dropdownButton,
  $lineSeparatorMenuItem,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const closeDropdownHandler = () => setIsOpen(false)
  const clickAwayRef = useClickAway(closeDropdownHandler)

  return (
    <DropdownWrapper ref={isOpen ? clickAwayRef : undefined}>
      <span onClick={() => setIsOpen((p) => !p)}>
        {dropdownButton || <KebabButton />}
      </span>
      <DropdownMenuStyled
        $direction={$direction}
        $isOpen={isOpen}
        $lineSeparatorMenuItem={$lineSeparatorMenuItem}
        // close menu on item click (leveraging event-bubbling)
        onClick={closeDropdownHandler}
      >
        {children}
      </DropdownMenuStyled>
    </DropdownWrapper>
  )
}
