import React, { useEffect, useState } from 'react'
import { Formik, Form, FormikHelpers } from 'formik'
import { startOAuthFlow } from './utils'
import { FormInner } from './FormInner'
import { makeAuthCredentialValidationSchema } from './validation'
import { Button } from '@components/Basic/ButtonNbe'
import {
  ActionButtonWrapper,
  FormWrapper,
  EditButtonWrapper,
  SubmitButtonWrapper,
  FormRow,
} from '@features/nbee/IntegrationCredentialsContent/styled'
import { useTranslation } from 'react-i18next'
import { IntegrationFormValues } from 'Nbee'
import { SubmitButton } from '@features/nbee/IntegrationCredentialsContent/AppAuthCredentialForm/SubmitButton'
import { useGetIntegrationUsage } from '@app/api/getIntegrationUsage'
import { InputFeedback } from '@components/Basic/InputFeedback'
import { Message } from '@components/Basic/Message'
import { InfoSection } from '@features/nbee/IntegrationCredentialsContent/InfoSection'
import { trackEvent } from '@app/dataTracking'
import { useGenIntegrationRedirectUri } from '@app/api/postGenIntegrationRedirectUri'
import { sendToast } from '@app/store/actions/ApplicationConfigurationActions'
import { parseApiError } from '@app/api/utils/error'
import { useAppDispatch } from '@app/store/hooks'

export type SubmitAction = 'update' | 'save-new'

interface Props {
  appId: number
  bridgeId?: number
  onSubmit: (
    formValues: IntegrationFormValues,
    formikHelpers: FormikHelpers<IntegrationFormValues>,
    action: SubmitAction
  ) => void
  initialValues: IntegrationFormValues
  isSavingApi: boolean
  isOauthUri?: boolean
  skipParams?: boolean
  appName: string
  integrationId?: number
  // when we receive an error after testing the credentials, we show it inline in the first scrren
  isNotValidIntegrationErrorMessage?: string
}

export const AppAuthCredentialForm: React.VFC<Props> = ({
  appId,
  bridgeId,
  onSubmit,
  initialValues,
  isSavingApi,
  isOauthUri,
  skipParams,
  appName,
  integrationId,
  isNotValidIntegrationErrorMessage,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  // this is used to control if we are in the first step (credentials form or oauth flow start) or if user is in the final step with the input "integration's name" (showName will be true)
  // after the oauth flow, this component will be mounted but will receive `skipParams` as true, in this case we take the user directly to the name step
  const [showName, setShowName] = useState(Boolean(skipParams))
  const [submitAction, setSubmitAction] = useState<SubmitAction>(
    integrationId ? 'update' : 'save-new'
  )
  const [hasError, setHasError] = useState(false)

  // State to disable the submit button when user select email that it's not verified yet or empty string
  // let hasError = true
  // This callback is passed to the form inner component which will pass it to the CredentialFieldVerifyEmail component
  const handleErrorsUpdate = (status: boolean) => {
    setHasError(status)
  }

  const { data: integrationUsage } = useGetIntegrationUsage(
    integrationId,
    bridgeId
  )

  const {
    mutate: generateRedirectUri,
    isLoading: isLoadingRedirectUri,
    error: genRedirectUriApiError,
    data: redirectUriData,
  } = useGenIntegrationRedirectUri()

  useEffect(() => {
    if (isNotValidIntegrationErrorMessage) {
      setShowName(false)
    }
  }, [isNotValidIntegrationErrorMessage])

  const totalBridges = integrationUsage?.data.totalBridges
  const activeBridges = integrationUsage?.data.activeBridges
  const isIntegrationUsed = Boolean(totalBridges)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={() => makeAuthCredentialValidationSchema(showName)}
      onSubmit={(values, formikHelpers) => {
        // When user proceed with the second step we send the `AuthorizeStarted` event
        if (!showName) {
          trackEvent({
            eventName: 'AuthorizeStarted',
            feature: 'NBEE',
            step: 'Integration',
            params: {
              appId: appId,
              appName: appName,
              integrationId: integrationId,
              integrationName: initialValues?.name,
              bridgeId: bridgeId,
              oauth: isOauthUri,
            },
          })
        }

        // if we found an OAuth uri, we must start the oauth flow
        if (!showName && isOauthUri) {
          formikHelpers.setSubmitting(true)
          generateRedirectUri(
            { appId: appId, credentials: values.credentials },
            {
              onSuccess: (data) => {
                const redirectUri = data?.data?.redirectUri as string
                const isLegacy = data?.data?.isLegacy as boolean
                startOAuthFlow({
                  credentials: values.credentials,
                  redirectUri: redirectUri,
                  appId: appId,
                  integrationId: integrationId,
                  isLegacy: isLegacy,
                })
              },
              onError: (error) => {
                dispatch(
                  sendToast({
                    title: 'Error',
                    messages: [parseApiError(error).message],
                    color: 'negative',
                  })
                )
              },
            }
          )
          return
        }

        // otherwise first submit is not the real one, we'll run validation just
        // to move to second step  where the user can select the name and perform
        // the real saving
        if (!showName) {
          setShowName(true)
          formikHelpers.setSubmitting(false)
          return
        }

        // real submit :)
        onSubmit(values, formikHelpers, submitAction)
      }}
    >
      {(formikProps) => {
        return (
          <FormWrapper>
            {isNotValidIntegrationErrorMessage && !showName ? (
              <div className={'mb-1'}>
                <Message $status={'error'}>
                  {isNotValidIntegrationErrorMessage}
                </Message>
              </div>
            ) : null}
            <Form>
              <FormRow>
                <div style={{ flex: 1, padding: '0 1rem' }}>
                  <FormInner
                    appId={appId}
                    appName={appName}
                    showName={showName}
                    handleErrorsUpdate={handleErrorsUpdate}
                  />
                  {integrationId && isIntegrationUsed && showName && (
                    <InputFeedback
                      $status={{
                        error: t('integrationAuthPopup.integrationUsage', {
                          totalBridges: totalBridges,
                          activeBridges: activeBridges,
                          action: 'Editing',
                        }),
                      }}
                    />
                  )}
                </div>
                {/* Video and Doc section */}
                {!showName && !isOauthUri && (
                  <InfoSection
                    appId={appId}
                    integrationId={integrationId}
                    integrationName={formikProps.values.name}
                    bridgeId={bridgeId}
                  />
                )}
              </FormRow>
              <ActionButtonWrapper>
                {integrationId && isIntegrationUsed && showName && (
                  <EditButtonWrapper>
                    <SubmitButton
                      appName={appName}
                      isDisabled={isSavingApi || formikProps.isSubmitting}
                      isLoading={
                        (isSavingApi && submitAction === 'save-new') ||
                        (formikProps.isSubmitting &&
                          submitAction === 'save-new')
                      }
                      isStepName={showName}
                      action={'save-new'}
                      onSubmitRequest={() => {
                        setSubmitAction('save-new')
                        formikProps.submitForm()
                      }}
                    />
                    <SubmitButton
                      appName={appName}
                      isDisabled={isSavingApi || formikProps.isSubmitting}
                      isLoading={
                        (isSavingApi && submitAction === 'update') ||
                        (formikProps.isSubmitting && submitAction === 'update')
                      }
                      isStepName={showName}
                      action={'update'}
                      onSubmitRequest={() => {
                        setSubmitAction('update')
                        formikProps.submitForm()
                      }}
                    />
                  </EditButtonWrapper>
                )}
                {!isIntegrationUsed && showName ? (
                  <SubmitButtonWrapper>
                    <SubmitButton
                      appName={appName}
                      isDisabled={isSavingApi || formikProps.isSubmitting}
                      isLoading={isSavingApi || formikProps.isSubmitting}
                      isStepName={showName}
                    />
                  </SubmitButtonWrapper>
                ) : !showName ? (
                  <SubmitButtonWrapper>
                    <SubmitButton
                      appName={appName}
                      isDisabled={
                        isSavingApi || formikProps.isSubmitting || hasError
                      }
                      isLoading={isSavingApi || formikProps.isSubmitting}
                      isStepName={showName}
                    />
                  </SubmitButtonWrapper>
                ) : null}

                <Button
                  type={'button'}
                  $variant={'link-secondary'}
                  $size={'standard'}
                  disabled={isSavingApi || formikProps.isSubmitting}
                  onClick={() => {
                    // back to previus step (credentials fields) is we are in the edit name screen
                    // otherwise we close the popup
                    if (showName) {
                      setShowName(false)
                    } else {
                      window.close()
                    }
                  }}
                >
                  {t('common.goBack')}
                </Button>
              </ActionButtonWrapper>
            </Form>
          </FormWrapper>
        )
      }}
    </Formik>
  )
}
