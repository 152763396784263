import React, { ReactNode } from 'react'
import { Topbar, TopbarBacklinkProps } from '@components/Topbar'
import {
  PageEditorStyled,
  PageTitle,
  PanelContainer,
  StepBarContainer,
} from './styled'
import { StepBar, StepBarStep } from '@components/StepBar'
import { Container } from '@components/Basic/Container'
import lbLogo from '@assets/images/logo_lb_extended.svg'
import { isInIframe } from '@app/utils/isInFrame'
import { Button } from '@app/components/Basic/ButtonNbe'
import { BridgeFormValues } from 'Nbee'
import { helpCenterUrl } from '@app/App'
import { Tooltip } from 'react-tooltip'
import { openZendeskChat } from '@app/utils/helpers'
import { useTranslation } from 'react-i18next'
import { FaLock } from 'react-icons/fa'
import { appRoutes } from '@app/routes'
import { useNavigate } from 'react-router'
import { FormikProps } from 'formik'
import { useGetUserModules } from '@app/api/getUserModules'

interface PageEditorProps {
  pageTitle?: string
  backlink?: TopbarBacklinkProps
  hasTopBar?: boolean
  steps?: StepBarStep[]
  activeStep?: number
  infoBar?: React.ReactNode
  topbarValue?: string
  topbarError?: string
  // if callback is not passed, item will not be editable
  onTopbarValueChange?: (newValue: string) => void
  IsTestEnableProp?: boolean
  bridgeIdProp?: string
  children?: ReactNode
  formikContext?: FormikProps<BridgeFormValues>
}

export const PageEditor: React.FC<PageEditorProps> = ({
  pageTitle,
  backlink,
  hasTopBar,
  activeStep = 0,
  steps,
  topbarValue,
  topbarError,
  onTopbarValueChange,
  infoBar,
  children,
  IsTestEnableProp,
  bridgeIdProp,
  formikContext,
}) => {
  const isEmbedded = isInIframe()
  const { t } = useTranslation()
  const { data: userModulesResponse } = useGetUserModules()

  const userModules = userModulesResponse?.modules || []
  const supportUserModule = userModules.find((m) => m.id === 'customer-support')

  const requiredLbPlanId = supportUserModule?.minimumPlan
  const customerSupportId = supportUserModule?.id
  const supportIsAvailable = supportUserModule?.enabled !== false

  const navigate = useNavigate()

  const { zE } = window

  // We use a standard variable let because setState in this case generate too many renders and loop error
  let bridgeId
  let testEnable

  // Check if we have the formik context to get bridgeId and testEnable,
  // if not as it is in the step 3 we use props coming from StepCompleted which is not in the formik context

  if (formikContext) {
    const { values } = formikContext
    bridgeId = values?.ui?.bridgeId
    testEnable = values?.ui?.testEnabled
  } else {
    testEnable = IsTestEnableProp
    bridgeId = bridgeIdProp
  }

  return (
    <PageEditorStyled>
      {hasTopBar ? (
        <>
          <Topbar
            backlink={backlink}
            defaultValue={topbarValue}
            errorMessage={topbarError}
            onNameChange={onTopbarValueChange}
            hideOnEmbeddable
          >
            {bridgeId && testEnable ? (
              <a
                target={'_blank'}
                href={`${process.env.REACT_APP_V1_URL}/leads/table/${bridgeId}?version=2`}
                rel='noreferrer'
              >
                <Button $size={'small'} $variant={'secondary'}>
                  Leads table
                </Button>
              </a>
            ) : null}
            {!supportIsAvailable && (
              <Tooltip
                id='support-button'
                place='top'
                variant='dark'
                style={{ zIndex: 1000 }}
              >
                <span>{t('auth.supportTooltip')}</span>
              </Tooltip>
            )}
            <span data-tooltip-id='support-button'>
              <Button
                onClick={() => {
                  if (!supportIsAvailable) {
                    navigate(
                      `${appRoutes.pricing.makeUrl()}?reason=${customerSupportId}&suggestPlan=${
                        requiredLbPlanId || 7
                      }`
                    )
                  } else {
                    openZendeskChat(zE)
                  }
                }}
                $size={'small'}
                $variant={'secondary'}
              >
                Support {!supportIsAvailable ? <FaLock /> : null}
              </Button>
            </span>
            <a target={'_blank'} href={helpCenterUrl} rel='noreferrer'>
              <Button $size={'small'} $variant={'secondary'}>
                Help center
              </Button>
            </a>
            <img src={lbLogo} width={140} alt={'LeadsBridge'} />
          </Topbar>
          {infoBar}
        </>
      ) : null}
      {/* when content is embedded in iframe we hide stepbar with js */}
      {/* it's not possibilel to rely on mediaqueries since the iframe size is too large */}
      {/* we also display a mini stepbar inside the <Panel> component */}
      {isEmbedded ? null : steps && steps.length ? (
        <StepBarContainer className={'text-center'}>
          <StepBar steps={steps} activeStep={activeStep} />
        </StepBarContainer>
      ) : (
        <div style={{ paddingTop: '3rem' }} />
      )}
      {pageTitle ? (
        <Container>
          <PageTitle data-testid='page_title' className={'text-center'}>
            {pageTitle}
          </PageTitle>
        </Container>
      ) : null}
      <PanelContainer>{children}</PanelContainer>
    </PageEditorStyled>
  )
}
