import * as Sentry from '@sentry/react'
import { ignoreList } from '@app/utils/sentry/ignoreList'

export const initSentry = () => {
  Sentry.init({
    dsn: 'https://acad65fcd1a943a893a7e2dfb763ebf7@o320992.ingest.sentry.io/6172904',
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.1,

    integrations: [new Sentry.Replay(), new Sentry.BrowserTracing()],
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/sampling/#sampling-transaction-events
    tracesSampleRate: 0,
    environment: process.env.DEPLOY_ENV,
    release: APP_VERSION,
    // loading Sentry only for production
    enabled: process.env.NODE_ENV === 'production',
    ...ignoreList,
  })
}
