import React, { useState } from 'react'
import { Button } from '@components/Basic/ButtonNbe'
import { Modal } from '@components/Basic/Modal'
import { useChangePlan } from '@app/api/postChangePlan'
import { sendToast } from '@app/store/actions/ApplicationConfigurationActions'
import { parseApiError } from '@app/api/utils/error'
import { useTranslation, Trans } from 'react-i18next'
import { useAppDispatch } from '@app/store/hooks'
import { trackEvent } from '@app/dataTracking'
import { useGetUserInfo } from '@app/api/getUserInfo'
import wellDoneImg from '@assets/images/well_done.png'
import { Content, ButtonWrapper } from './styled'
import { calculateDaysBetweenDates } from '@app/utils/calcDaysBetweenDates'

interface ModalProps {
  isOpen: boolean
  onCloseModal: () => void
}

export const ModalChangePlan: React.FC<ModalProps> = ({
  isOpen,
  onCloseModal,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [hasSwitched, setHasSwitched] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const {
    data: userData,
    refetch: userDataRefetch,
    isRefetching: isUserDataRefetching,
  } = useGetUserInfo()

  const endOfTermsDate = new Date(
    userData?.pricing?.endOfTermsDate as string
  ).getTime()
  const dateNow = new Date().getTime()
  const remainDays = calculateDaysBetweenDates(endOfTermsDate, dateNow)

  // change plan
  const { mutate: changePlanMutation, isLoading: isChangingPlan } =
    useChangePlan()

  const changePlan = ({ planId }: { planId: string }) => {
    changePlanMutation(
      {
        planId: planId,
        trial: false,
      },
      {
        onSuccess: () => {
          userDataRefetch().then(() => setHasSwitched(true))
          trackEvent({
            eventName: 'PlanChanged',
            feature: 'Pricing',
            step: 'Table',
            params: {
              custom: {
                previousPlanId: userData?.pricing?.cbPlanId,
                newPlanId: planId,
                previousMrr: userData?.pricing?.mrr
                  ? (userData?.pricing?.mrr || 0) / 100
                  : undefined,
                newMrr: 0, // here is always freemium
                trial: false,
              },
            },
          })
        },
        onError: (error) => {
          dispatch(
            sendToast({
              title: 'Error',
              messages: [parseApiError(error).message],
              color: 'negative',
            })
          )
        },
      }
    )
  }

  return (
    <Modal
      data-id={'modal-change-plan'}
      isOpen={isOpen}
      isWide={hasSwitched || showConfirm}
      onCloseModal={() => {
        setHasSwitched(false)
        if (isChangingPlan) {
          return
        }
        onCloseModal()
      }}
    >
      <Content>
        <h2>
          {hasSwitched
            ? t('billing.pricing.modalDowngradeTitleConfirm', {
                remainDays: remainDays,
              })
            : showConfirm
            ? t('billing.pricing.modalDowngradeTitleSuccess')
            : t('billing.pricing.modalDowngradeTitle')}
        </h2>
        <hr />
        <p
          style={
            hasSwitched
              ? { marginBottom: '.5rem' }
              : showConfirm
              ? { marginBottom: '2rem' }
              : {}
          }
        >
          {hasSwitched ? (
            <Trans
              ns={'all'}
              i18nKey={'billing.pricing.modalDowngradeSubConfirm'}
              components={{
                br: <br />,
              }}
              values={{ remainDays: remainDays }}
            />
          ) : showConfirm ? (
            t('billing.pricing.modalDowngradeTextSuccess')
          ) : (
            t('billing.pricing.modalDowngradeText')
          )}
        </p>
        {hasSwitched || showConfirm ? (
          <img
            style={showConfirm ? { marginBottom: '1rem' } : {}}
            src={wellDoneImg}
            alt={'wellDone'}
          />
        ) : null}
        {hasSwitched && (
          <p style={{ margin: '.5rem auto 1.5rem', width: '80%' }}>
            {t('billing.pricing.modalDowngradeTextConfirm')}
          </p>
        )}
        <ButtonWrapper>
          {hasSwitched || showConfirm ? null : (
            <Button
              $size={'large'}
              $variant={'link-primary'}
              onClick={onCloseModal}
            >
              {t('billing.pricing.modalDowngradeCtaCancel')}
            </Button>
          )}

          <Button
            onClick={
              !hasSwitched && !showConfirm
                ? () => changePlan({ planId: 'freemium' })
                : () => {
                    onCloseModal()
                    setHasSwitched(false)
                  }
            }
            disabled={isChangingPlan || isUserDataRefetching}
            $loading={isChangingPlan || isUserDataRefetching}
            $size={'large'}
            $variant={'blue'}
          >
            {hasSwitched
              ? t('billing.pricing.modalDowngradeCtaOk')
              : showConfirm
              ? t('billing.pricing.modalDowngradeCtaSuccess')
              : t('billing.pricing.modalDowngradeCtaConfirm')}
          </Button>
        </ButtonWrapper>
      </Content>
    </Modal>
  )
}
