import { FormikInputField } from '@components/Formik/FormikInputField'
import React from 'react'
import {
  AccountCreationFormWrapperStyled,
  AccountCreationInputGroup,
  IntegrationLogoWrapper,
} from './styled'
import { useGetCurrencies } from '@app/api/getCurrencies'
import { useGetTimezones } from '@app/api/getTimezones'
import { useTranslation } from 'react-i18next'
import { FormikInputSmartSelect } from '@components/Formik/FormikInputSmartSelect'
import { useFormikContext } from 'formik'
import { IntegrationLogo } from '@features/googleAdsAccount/AccountActivate/styled'
import googleAdsLogo from '@assets/images/gads-logo_2.svg'

export const AccountCreationFormInner: React.FC = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext()

  const { data: currenciesData, isLoading: isLoadingCurrencies } =
    useGetCurrencies()
  const { data: timezonesData, isLoading: isLoadingTimezones } =
    useGetTimezones()

  return (
    <>
      <IntegrationLogoWrapper>
        <IntegrationLogo>
          <img src={googleAdsLogo} alt={'logo'} width={170} />
        </IntegrationLogo>
        <h2>{t('gaac.steps.stepCreate.title')}</h2>
      </IntegrationLogoWrapper>

      <AccountCreationFormWrapperStyled>
        <AccountCreationInputGroup>
          <FormikInputField
            data-testid='display_name_input'
            label={t('gaac.steps.stepCreate.labelFullname')}
            name={'fullname'}
            type={'text'}
          />
          <FormikInputField
            data-testid='email_input'
            label={t('gaac.steps.stepCreate.labelEmail')}
            name={'email'}
            type={'text'}
          />
        </AccountCreationInputGroup>
        <AccountCreationInputGroup>
          <FormikInputSmartSelect
            initialValues={currenciesData || []}
            name={'currency'}
            label={t('gaac.steps.stepCreate.labelCurrency')}
            isLoading={isLoadingCurrencies}
          />
          <FormikInputSmartSelect
            initialValues={timezonesData || []}
            name={'timezone'}
            label={t('gaac.steps.stepCreate.labelTimezone')}
            isLoading={isLoadingTimezones}
          />
        </AccountCreationInputGroup>
      </AccountCreationFormWrapperStyled>
    </>
  )
}
