import * as React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import store from './store'
import { Provider } from 'react-redux'
import ToastComponent from './components/ToastComponent'
import { QueryClient, QueryClientProvider } from 'react-query'
import { createServerInit } from './mockapi'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ThemeProvider } from 'styled-components'
import { defaultTheme } from '@app/styles/theme/default'
import './i18n'
import { AlertComponent } from '@components/Alert'
import { ErrorBoundary } from '@sentry/react'
import { ErrorPage } from '@components/ErrorBoundary/ErrorPage'
import { initSentry } from '@app/utils/sentry'
import { IntercomProvider } from 'react-use-intercom'

if (process.env.REACT_USE_MOCK_API === 'true') {
  createServerInit()
}

const INTERCOM_APP_ID = 'u5owpe8i'
const INTERCOM_API_BASE = 'https://api-iam.intercom.io'
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  },
})

initSentry()

const root = ReactDOM.createRoot(document.getElementById('root') as Element)

root.render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={defaultTheme}>
          <ErrorBoundary fallback={() => <ErrorPage />}>
            <IntercomProvider
              appId={INTERCOM_APP_ID}
              apiBase={INTERCOM_API_BASE}
            >
              <App />
            </IntercomProvider>
          </ErrorBoundary>
          <ToastComponent />
          <AlertComponent />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </QueryClientProvider>
)
