import React, { useState } from 'react'
import { BridgeFilterCondition } from 'Nbee'
import { ApiBridgeFieldDefinition, ApiUserModuleItem } from 'BackendApi'
import { Button } from '@components/Basic/ButtonNbe'

import {
  AddFilterButtonWrapper,
  ButtonStyledLabel,
  EditIcon,
  FilterIcon,
  HasFilterMessage,
  HasFilterMessageTitle,
  StarterLabel,
} from './styled'
import { AddFilterButtonModal } from '@features/nbee/AddFilterButton/AddFilterButtonModal'
import { FaFilter, FaLock } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { minimumPlanName } from '@features/nbee/FieldsMappingForm'
import { useUpdateBridgeToPricing } from '@features/nbee/utils'

interface Props {
  initialFilterRules?: BridgeFilterCondition[]
  allSourceFields?: ApiBridgeFieldDefinition[]
  bridgeId: number
  onRefetchBridgeFormValues?: () => void
  isBridgeDisabled: boolean | undefined
  filterUserModules: ApiUserModuleItem | undefined
}

export const AddFilterButton: React.FC<Props> = ({
  initialFilterRules,
  allSourceFields,
  bridgeId,
  onRefetchBridgeFormValues,
  isBridgeDisabled,
  filterUserModules,
}) => {
  // State variables and functions to handle modal display on page
  const [rules, setRules] = useState<BridgeFilterCondition[]>(
    initialFilterRules || []
  )

  const [isModalOpen, toggleIsModalOpen] = useState<boolean>(false)
  const userHasFiltersEnabled = filterUserModules?.enabled
  const minimumPlanRequired = filterUserModules?.minimumPlan
  const moduleFilterId = filterUserModules?.id

  const updateBridgeToPricing = useUpdateBridgeToPricing(
    bridgeId,
    moduleFilterId,
    minimumPlanRequired
  )

  const planLabelName = minimumPlanRequired
    ? minimumPlanName[minimumPlanRequired.toString()].toUpperCase()
    : ''

  const handleCloseModal = () => {
    toggleIsModalOpen(false)
  }
  const handleLabelUpdate = (newRules: BridgeFilterCondition[]) =>
    setRules(newRules)
  const { t } = useTranslation()

  // Helper variables to easily handle button label message
  const hasFilters = !!rules?.length
  const firstRule = rules?.[0]
  const firstRuleField = firstRule?.field.label
  const firstRuleCondition = firstRule?.operator
    .toLowerCase()
    .replace(/_/g, ' ')
  const firstRuleValue = firstRule?.values
  const otherRulesNumber = rules?.length && rules.length - 1
  const hasMoreThanOneRule = !!otherRulesNumber
  const hasTwoRules = rules?.length === 2
  const buttonLabel = hasFilters ? (
    isBridgeDisabled ? (
      <>{'Show Filter'}</>
    ) : (
      <>
        {t('nbee.filters.buttonHasFiltersTitle')}
        <EditIcon />
      </>
    )
  ) : (
    <> {t('nbee.filters.buttonNoFiltersTitle')}</>
  )

  const handleOpenModal = () => {
    if (userHasFiltersEnabled || (!userHasFiltersEnabled && hasFilters)) {
      toggleIsModalOpen(true)
    } else {
      // Redirect user to pricing page
      // if they do not have the 'filters' module
      updateBridgeToPricing()
    }
  }

  return (
    <>
      <AddFilterButtonWrapper>
        <FilterIcon hasFilters={hasFilters}>
          <FaFilter />
        </FilterIcon>
        {hasFilters && (
          <HasFilterMessage data-testid='add-filter-button-message'>
            <HasFilterMessageTitle>
              {t('nbee.filters.buttonHasFiltersMessageTitle')}
            </HasFilterMessageTitle>
            {t('nbee.filters.buttonHasFiltersMessageOneRule', {
              firstRuleField: firstRuleField,
              firstRuleCondition: firstRuleCondition,
              firstRuleValue: firstRuleValue,
            })}
            {hasMoreThanOneRule && (
              <span>
                {t('nbee.filters.buttonHasFiltersMessageMoreRules', {
                  otherRulesNumber: otherRulesNumber,
                })}
                {hasTwoRules ? '' : 's'}
              </span>
            )}
          </HasFilterMessage>
        )}
        <Button
          data-testid='add-filter-button'
          disabled={isBridgeDisabled && !hasFilters}
          type={'button'}
          $variant={'link-primary'}
          onClick={handleOpenModal}
          style={{ fontSize: '0.95em', paddingLeft: '0.2em' }}
        >
          <ButtonStyledLabel>
            {buttonLabel}{' '}
            {!userHasFiltersEnabled && (
              <StarterLabel>
                <FaLock /> {planLabelName}
              </StarterLabel>
            )}
          </ButtonStyledLabel>
        </Button>
      </AddFilterButtonWrapper>
      {isModalOpen && (
        <AddFilterButtonModal
          modalTitle={
            !hasFilters
              ? 'Add Filter'
              : isBridgeDisabled
              ? 'Show Filter'
              : 'Edit Filter'
          }
          isOpen={isModalOpen}
          onCloseModal={handleCloseModal}
          sourceFields={allSourceFields}
          initialFilterRules={rules}
          bridgeId={bridgeId}
          onSaveOrDeleteFilterRules={handleLabelUpdate}
          filterUserModules={filterUserModules}
          isBridgeDisabled={!!isBridgeDisabled}
        />
      )}
    </>
  )
}
