import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import {
  InputStyled,
  InputWrapperStyled,
  InputStyledProps,
  InputSlot,
} from './styled'
import { Label } from '@components/Form/Label'
import {
  InputFeedbackStatus,
  InputFeedback,
} from '@components/Basic/InputFeedback'
import { FloatingLabelStyled } from '@components/Form/InputSmartSelect/styled'

export interface InputFieldAttrProps
  extends InputHTMLAttributes<HTMLInputElement> {
  $status?: InputFeedbackStatus
  name: string
  type: 'text' | 'email' | 'number' | 'password' | 'tel' | 'url'
  $direction?: InputStyledProps['$direction']
  $fluid?: boolean
  slotLeft?: React.ReactNode
  slotRight?: React.ReactNode
  label?: string
  $hasMargin?: boolean
  defaultLabel?: string
  floatingLabel?: string
  fieldIsUnavailable?: boolean
}

export const InputField: React.FC<InputFieldAttrProps> = ({
  label,
  slotLeft,
  slotRight,
  $status,
  $direction,
  $fluid,
  $hasMargin,
  defaultLabel,
  floatingLabel,
  fieldIsUnavailable,
  ...props
}) => {
  const inputId = `${props.name}`
  const [isFocused, setIsFocused] = useState(false)
  const [hasValue, setHasValue] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    setIsFocused(true)
    if (props.onFocus) {
      props.onFocus(event)
    }
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    setIsFocused(false)
    if (props.onBlur) {
      props.onBlur(event)
    }
  }

  useEffect(() => {
    if (inputRef.current?.value) {
      setHasValue(true)
    } else {
      setHasValue(false)
    }
  }, [inputRef.current?.value])

  return (
    <InputStyled
      $direction={$direction || 'column'}
      $status={$status}
      type={props.type}
      name={props.name}
      id={inputId}
      $hasMargin={$hasMargin}
      className={props.className}
    >
      {label && (
        <Label $disabled={props.disabled} htmlFor={inputId} $label={label} />
      )}
      <InputWrapperStyled>
        {floatingLabel && (
          <FloatingLabelStyled
            htmlFor={inputId}
            isFloating={isFocused || hasValue}
            onClick={() => inputRef?.current?.focus()}
          >
            {isFocused || hasValue ? floatingLabel.toUpperCase() : defaultLabel}
          </FloatingLabelStyled>
        )}
        {slotLeft ? <InputSlot $slot={'left'}>{slotLeft}</InputSlot> : null}
        <input
          {...props}
          ref={inputRef}
          type={props.type}
          name={props.name}
          id={inputId}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={
            defaultLabel && floatingLabel && (isFocused || hasValue)
              ? {
                  paddingTop: '.6rem',
                  paddingBottom: '.1rem',
                  marginTop: '.6rem',
                  height: '34px',
                  color: (fieldIsUnavailable && 'red') || undefined,
                }
              : defaultLabel && floatingLabel && !isFocused && !hasValue
              ? {
                  height: '43px',
                }
              : {}
          }
        />
        {slotRight ? <InputSlot $slot={'right'}>{slotRight}</InputSlot> : null}
      </InputWrapperStyled>

      {$status && <InputFeedback $status={$status} />}
    </InputStyled>
  )
}
