import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { Button as ButtonNbe } from '@components/Basic/ButtonNbe'
import {
  FooterStyled,
  SignupFormWrapper,
  SeparatorStyled,
  TitleStyled,
  FormRow,
  SocialProvidersWrapper,
  TermsText,
  ContentWrapper,
  HeaderTop,
} from './styled'
import { FormikInputField } from '@components/Formik/FormikInputField'
import {
  initialFormValues,
  initialFormValuesAppsumo,
  validationSchema,
  validationSchemaAppsumo,
} from '@features/signup/SignupForm/formConfig'
import { useNavigate, useLocation, matchPath } from 'react-router-dom'
import { appRoutes } from '@app/routes'
import { ButtonProvider, SignupProviderName } from '@components/ButtonProvider'
import { useAppDispatch } from '@app/store/hooks'
import { sendToast } from '@app/store/actions/ApplicationConfigurationActions'
import { Loader } from '@components/Basic/Loader'
import { Trans, useTranslation } from 'react-i18next'
import {
  getPersistentSignupParams,
  useHideParamsFromString,
} from '@features/signup/SignupForm/useHideParamsFromString'
import { useSignupWithEmail } from '@app/api/signup'
import { parseApiError } from '@app/api/utils/error'
import { handleSignupWithEmailSuccess } from '@features/signup/SignupForm/signupWithEmailUtils'
import { ReCaptcha } from '@components/ReCaptcha'
import { identifyUserByUid, trackEvent } from '@app/dataTracking'
import { useFetchIntegrationsMetaForBridgeByUrl } from '@app/api/integrationTools'
import { Hub } from '@aws-amplify/core'
import {
  AUTH_STATE_CHANGE_EVENT,
  AuthState,
  UI_AUTH_CHANNEL,
} from '@aws-amplify/ui-components'
import { useFastAppSurveyIndustries } from '@app/api/fastAppSurveyIndustries'
import { FormikInputSelect } from '@components/Formik/FormikInputSelect'
import { FormikInputCheckbox } from '@components/Formik/FormikInputCheckbox'
import { sleep } from '@app/utils/helpers'
import Tap from '@tapfiliate/tapfiliate-js'
import {
  AffiliateIdDataFromCookies,
  getAffiliateIdFromCookies,
  setCookie,
} from '@app/utils/cookieUtils'

export interface Props {
  showFacebookButton?: boolean
  showGoogleButton?: boolean
  title?: string
  onSignUp?: () => void
  isAppSumo?: boolean
  showIndustries?: boolean
  showCoupon?: boolean
  header?: React.ReactNode
  testId?: string
}

export const SignUpForm: React.FC<Props> = ({
  title,
  showGoogleButton,
  showFacebookButton,
  isAppSumo,
  showIndustries,
  showCoupon,
  header,
  testId,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const getIndustries = useFastAppSurveyIndustries()

  // .../bc/lb/facebook-leads-ads/google-sheets/?cc=XXX&cbPlan=YYY&td=15
  useHideParamsFromString()
  const signupParams = getPersistentSignupParams()
  const matchBBuPath = matchPath(
    {
      path: appRoutes.signupBbu.path,
    },
    location.pathname
  )

  const isExactMatch = location.pathname === appRoutes.signupBbu.path

  const matchPartnerPath = matchPath(
    {
      path: appRoutes.signupPartner.path,
    },
    location.pathname
  )

  const couponCode = matchPartnerPath?.params.couponCode

  const currentPlan = isAppSumo
    ? 'appsumo-2022'
    : signupParams.cbPlan || 'freemium'

  /* const isFreePlan =
    currentPlan === 'freemium' || currentPlan.includes('starter') */

  const updatedAppSumoFormValues = {
    ...initialFormValuesAppsumo,
    couponCode: couponCode,
  }

  const bbuSourceSlug = matchBBuPath?.params.source
  const bbuDestinationSlug = matchBBuPath?.params.destination
  const { data: bbuAppsInfo } = useFetchIntegrationsMetaForBridgeByUrl(
    bbuSourceSlug,
    bbuDestinationSlug
  )
  const isValidBbuUrl = Boolean(
    bbuAppsInfo?.source?.id && bbuAppsInfo.destination?.id
  )

  const [affiliateData, setAffiliateData] = React.useState<
    AffiliateIdDataFromCookies | undefined
  >({
    tapClickId: undefined,
    tapVisitorId: undefined,
  })

  // Check if cookies are already there
  React.useEffect(() => {
    const existingCookieData = getAffiliateIdFromCookies()
    setAffiliateData(existingCookieData)
  }, [])

  // Stores click Id and visitor Id in cookies
  React.useEffect(() => {
    // if cookies are already there (and they've been stored in the affiliateData state), exit early
    if (
      getAffiliateIdFromCookies()?.tapClickId &&
      getAffiliateIdFromCookies()?.tapVisitorId
    )
      return
    // If cookies are not present, check if ref is present in query params
    // If it is not, exit early
    if (!signupParams.ref) return

    // If cookies aren't present, but we do have the ref code,
    // initialize TapFiliate, then execute Tap.detect
    Tap.init(`${process.env.TAPFILIATE_ACCOUNT_ID}`)
    Tap.detect(
      {
        always_callback: true,
        referral_code: signupParams.ref,
      },
      (response: any, args: { id?: string; vid?: string }) => {
        console.log(
          'Tap.detect callback response',
          response,
          'Tap.detect args',
          args
        )

        if (response === 'Tracking request failed') {
          console.error('Error in Tap.detect:', response)
          return
        }

        const { id: argId, vid: argVid } = args || {}

        const id = argId
        const vid = argVid

        if (id && vid) {
          // create cookies with tap click id and visitor id values (which I'm sure I don't have already)
          setCookie('tap.id', id)
          setCookie('tap.vid', vid)

          // Set affiliate data directly after setting cookies
          setAffiliateData({ tapClickId: id, tapVisitorId: vid })
        }
      }
    )
  }, [signupParams.ref]) // executes this useEffect only when the value of signupParams.ref changes (-> only when we do have a ref code in the signup page url)

  const {
    mutate: signupWithEmail,
    isLoading: isDoingSingup,
    error: signupWithEmailApiError,
    data: signupReponse,
  } = useSignupWithEmail()

  const onAuthSuccess = async () => {
    await sleep(500)

    const firstLoginUrlParam = 'lbfsgn'

    // we should already have an user id, so we try to track the signup event with an UID already attached
    const newUserId = signupReponse?.user.userId
      ? `UID-${signupReponse?.user.userId}`
      : undefined
    const userEmail = signupReponse?.user.email

    // we wait to have the user identified before sending the track event
    await identifyUserByUid(newUserId, userEmail).catch(() => {
      console.log('not identified - segment not active')
    })

    trackEvent({
      eventName: 'SignUp',
      feature: 'Authentication',
      step: 'Signup',
      params: {
        custom: {
          planId: currentPlan,
          mrr: (signupReponse?.pricing?.mrr || 0) / 100,
          channel: isValidBbuUrl ? 'lb' : 'signup',
          sourceId: bbuAppsInfo?.source?.id,
          sourceName: bbuAppsInfo?.source?.name,
          destinationId: bbuAppsInfo?.destination?.id,
          destinationName: bbuAppsInfo?.destination?.name,
        },
        userId: newUserId,
      },
    })

    if (bbuSourceSlug && bbuDestinationSlug && isValidBbuUrl) {
      // re-render Apps.tsx routing and this time will match private route for Step1 with bbu
      Hub.dispatch(UI_AUTH_CHANNEL, {
        event: AUTH_STATE_CHANGE_EVENT,
        message: AuthState.SignedIn,
        data: null,
      })
    } else if (signupParams.sourceId || signupParams.destinationId) {
      // in case we have sourceId or destinationId in query string, we take user to nbee step1 with source or destination pre-selected
      navigate({
        pathname: appRoutes.nbeeStep1New.makeUrl(),
        search: signupParams.sourceId
          ? `sourceId=${signupParams.sourceId}&${firstLoginUrlParam}=true&currentPlan=${currentPlan}`
          : `destinationId=${signupParams.destinationId}&${firstLoginUrlParam}=true&currentPlan=${currentPlan}`,
      })
    } else {
      // generic signup, we take user NBEE step1 right after updating auth state
      Hub.dispatch(UI_AUTH_CHANNEL, {
        event: AUTH_STATE_CHANGE_EVENT,
        message: AuthState.SignedIn,
        data: null,
      })
      navigate({
        pathname: appRoutes.nbeeStep1New.makeUrl(),
        search: `${firstLoginUrlParam}=true&currentPlan=${currentPlan}`,
      })
    }
  }

  const showGenericError = (message: string) => {
    setIsLoading(false)
    dispatch(
      sendToast({
        title: 'Error',
        messages: [message],
        color: 'negative',
      })
    )
  }

  useEffect(() => {
    if (signupReponse) {
      handleSignupWithEmailSuccess({
        credentials: signupReponse?.credentials,
        onError: showGenericError,
      }).then(() => {
        onAuthSuccess()
      })
    }
  }, [signupReponse])

  // on signupWithEmailError
  useEffect(() => {
    if (signupWithEmailApiError) {
      const parsedError = parseApiError(signupWithEmailApiError)
      showGenericError(parsedError.message)
      trackEvent({
        eventName: 'UnexpectedErrorThrown',
        feature: 'Authentication',
        step: 'Signup',
        params: {
          custom: {
            errorCode: parsedError.code,
            errorDescription: parsedError.message,
            planId: currentPlan,
          },
        },
      })
    }
  }, [signupWithEmailApiError])

  const signupProviders: {
    name: SignupProviderName
    show?: boolean
  }[] = [
    {
      name: 'Google',
      show: showGoogleButton,
    },
    {
      name: 'Facebook',
      show: showFacebookButton,
    },
  ]

  return (
    <SignupFormWrapper data-testid={testId} isAppSumo={isAppSumo}>
      {isLoading && <Loader $active $dimmer />}

      <ReCaptcha>
        {({ getCaptchaToken }) => (
          <ContentWrapper isAppSumo={isAppSumo}>
            {header && <HeaderTop>{header}</HeaderTop>}
            {title && <TitleStyled>{title}</TitleStyled>}
            <SocialProvidersWrapper>
              {signupProviders.map(({ show, name }) =>
                show ? (
                  <ButtonProvider
                    key={name}
                    scope={'SignUp'}
                    provider={name}
                    getCaptchaToken={getCaptchaToken}
                    signupParams={signupParams}
                    onBeforeAuth={() => {
                      setIsLoading(true)
                    }}
                    onAuthError={showGenericError}
                    onAuthSuccess={onAuthSuccess}
                  />
                ) : null
              )}
            </SocialProvidersWrapper>

            {showFacebookButton || showGoogleButton ? (
              <SeparatorStyled>
                <hr />
                <span>Or</span>
                <hr />
              </SeparatorStyled>
            ) : null}

            <Formik
              initialValues={
                isAppSumo ? updatedAppSumoFormValues : initialFormValues
              }
              validationSchema={
                isAppSumo ? validationSchemaAppsumo : validationSchema
              }
              onSubmit={async (values) => {
                setIsLoading(true)
                const recaptchaToken = await getCaptchaToken()
                if (!recaptchaToken) {
                  setIsLoading(false)
                  return
                }
                console.log(
                  'calling signupWithEmail',
                  'tapVisitorId',
                  affiliateData?.tapVisitorId,
                  'tapClickId',
                  affiliateData?.tapClickId
                )
                signupWithEmail({
                  requestBody: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    referralUri: document.referrer || window.location.origin,
                    trialDays: isAppSumo
                      ? undefined
                      : `${signupParams.trialDays}`,
                    cbPlan: currentPlan,
                    couponCode:
                      values.couponCode || signupParams.couponCode || undefined,
                    industry: isAppSumo ? values.industry : '',
                    bbuUri: isAppSumo
                      ? undefined
                      : isExactMatch && isValidBbuUrl
                      ? `${window.location.origin}${location.pathname}`
                      : undefined,
                    recaptchaToken: recaptchaToken || '',
                    tapClickId: affiliateData?.tapClickId || undefined,
                    tapVisitorId: affiliateData?.tapVisitorId || undefined,
                  },
                })
              }}
            >
              {() => {
                return (
                  <Form>
                    <FormRow>
                      <FormikInputField
                        name='firstName'
                        type='text'
                        label='First Name'
                        $fluid
                        $hasMargin
                        placeholder={'John'}
                        className={'mb-0'}
                      />
                      <FormikInputField
                        name='lastName'
                        type='text'
                        label='Last Name'
                        $fluid
                        $hasMargin
                        placeholder={'Appleseed'}
                        className={'mb-0'}
                      />
                    </FormRow>

                    <FormikInputField
                      name='email'
                      type='email'
                      label='Email'
                      $fluid
                      $hasMargin
                      placeholder={'example@mail.com'}
                      className={'mb-0'}
                    />
                    {isAppSumo && showIndustries ? (
                      <FormikInputSelect
                        $options={getIndustries.data || []}
                        $loading={getIndustries.isLoading}
                        disabled={Boolean(getIndustries.error)}
                        $optionsLabel={
                          getIndustries.error
                            ? 'Unable to retrieve industries'
                            : 'Please select industry'
                        }
                        name={'industry'}
                        $label={'Industry'}
                        $hasMargin
                      />
                    ) : null}

                    {isAppSumo && showCoupon ? (
                      <FormikInputField
                        name='couponCode'
                        type='text'
                        label={t('auth.signup.couponLabelAppSumo')}
                        $fluid
                        $hasMargin
                        className={'mb-0'}
                      />
                    ) : null}
                    <div style={{ marginTop: '1rem' }}>
                      <FormikInputCheckbox name='privacyConsent'>
                        <TermsText>
                          <Trans
                            ns={'all'}
                            i18nKey={
                              isAppSumo
                                ? 'auth.signup.termsAndConditionAppSumo'
                                : 'auth.signup.termsAndCondition'
                            }
                            components={{
                              a: <a />,
                            }}
                          />
                        </TermsText>
                      </FormikInputCheckbox>
                    </div>
                    <div style={{ margin: '1.6rem 0 1rem' }}>
                      <ButtonNbe
                        type={'submit'}
                        $variant={'action'}
                        disabled={isDoingSingup || isLoading}
                        $size={'large'}
                        $fluid
                      >
                        {isAppSumo
                          ? t('auth.signup.ctaWithAppsumo')
                          : t('auth.signup.ctaViaEmail')}
                      </ButtonNbe>
                    </div>
                  </Form>
                )
              }}
            </Formik>

            {isAppSumo || !showFacebookButton || !showGoogleButton ? (
              <SeparatorStyled>
                <hr />
                <span>or</span>
                <hr />
              </SeparatorStyled>
            ) : null}
            <FooterStyled isAppSumo={isAppSumo}>
              <ButtonNbe
                style={{ padding: '0', textDecoration: 'underline' }}
                $variant={'link-secondary'}
                onClick={() => {
                  navigate(appRoutes.signin.makeUrl())
                }}
              >
                {t('auth.signup.alreadyAnAccount')}
              </ButtonNbe>
              <ButtonNbe
                style={{ margin: '0' }}
                $variant={'outlined-primary'}
                onClick={() => {
                  navigate(appRoutes.signin.makeUrl())
                }}
              >
                {t('auth.signup.signIn')}
              </ButtonNbe>
            </FooterStyled>
          </ContentWrapper>
        )}
      </ReCaptcha>
    </SignupFormWrapper>
  )
}
