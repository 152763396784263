import React from 'react'
import {
  FeaturesStyled,
  PlanDescription,
  PlanHeaderLeft,
  PlanHeaderRight,
  PlanName,
  PlanType,
  PriceDescription,
  PriceDiscount,
  PriceStyled,
  PricingCardBox,
  PricingCardHeaderStyled,
  PricingCardTopBarLabel,
  PricingCardTopBarWrapper,
  PricingCardWrapper,
} from '@features/pricing/PricingCard/styled'

import { Tooltip } from 'react-tooltip'
import { ApiMainFeatures } from 'BackendApi'
import { Trans, useTranslation } from 'react-i18next'
import { useChangePlan } from '@app/api/postChangePlan'
import { useGetUserInfo } from '@app/api/getUserInfo'
import { sendToast } from '@app/store/actions/ApplicationConfigurationActions'
import { parseApiError } from '@app/api/utils/error'
import { useAppDispatch } from '@app/store/hooks'

export interface PricingCardProps {
  planName: string
  planDescription: string
  price: number
  priceDescription: string
  features: ApiMainFeatures[]
  isYearlyChecked: boolean
  yearlyDiscountPercentage?: string
  isActive?: boolean
  isRecommended?: boolean
  isCurrentPlan?: boolean
  showUndo?: boolean
  remainDays?: number
  children?: React.ReactNode
}

export const planNameToShow: Record<string, string> = {
  freemium: 'Free',
  starter: 'Starter',
  pro: 'Pro',
  business: 'Business',
}

export const getPlanNameToShow = (planName: string) => {
  return planNameToShow[planName] || planNameToShow
}

export const PricingCard: React.FC<PricingCardProps> = ({
  features,
  planName,
  planDescription,
  price,
  priceDescription,
  isActive,
  isRecommended,
  isCurrentPlan,
  isYearlyChecked,
  yearlyDiscountPercentage,
  children,
  remainDays,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { data: userData, refetch: refetchUserData } = useGetUserInfo()
  // change plan
  const { mutate: changePlanMutation, isLoading: isChangingPlan } =
    useChangePlan()

  // UNDO SWITCH TO FREE FEATURE
  const endOfTermsDate = userData?.pricing.endOfTermsDate
  const showUndoPro =
    endOfTermsDate &&
    (planName === 'PRO' || planName === 'STARTER') &&
    isCurrentPlan

  const planId = userData?.pricing?.cbPlanId

  const handleUndo = () => {
    if (planId) {
      changePlanMutation(
        { planId: planId, trial: false },
        {
          onSuccess: () => {
            refetchUserData()
          },
          onError: (error) => {
            dispatch(
              sendToast({
                title: 'Error',
                messages: [parseApiError(error).message],
                color: 'negative',
              })
            )
          },
        }
      )
    }
  }

  return (
    <PricingCardWrapper planName={planName.toLocaleLowerCase()}>
      {showUndoPro ? (
        <PricingCardTopBarWrapper>
          <PricingCardTopBarLabel
            isLoading={isChangingPlan}
            showUndoPro={showUndoPro}
          >
            <p>
              <Trans
                ns={'all'}
                i18nKey={'billing.pricing.switchingToFreeCardHeader'}
                values={{ remainDays: remainDays }}
              />
              <span onClick={isChangingPlan ? () => null : () => handleUndo()}>
                Undo
              </span>
            </p>
          </PricingCardTopBarLabel>
        </PricingCardTopBarWrapper>
      ) : isRecommended || isCurrentPlan ? (
        <PricingCardTopBarWrapper>
          <PricingCardTopBarLabel isCurrentPlan={isCurrentPlan}>
            {isCurrentPlan
              ? t('billing.pricing.pricingColumnCurrent')
              : isRecommended
              ? t('billing.pricing.pricingColumnRecommended')
              : null}
          </PricingCardTopBarLabel>
        </PricingCardTopBarWrapper>
      ) : null}

      <PricingCardBox isActive={isActive} isRecommended={isRecommended}>
        <PlanType>
          {planName.toLowerCase() !== 'business'
            ? 'self-service'
            : 'managed service'}
        </PlanType>

        <PricingCardHeaderStyled>
          <PlanHeaderLeft>
            <PlanName planName={planName.toLocaleLowerCase()}>
              <> {getPlanNameToShow(planName.toLowerCase())} </>
            </PlanName>
            <PlanDescription planName={planName.toLocaleLowerCase()}>
              {planDescription}
            </PlanDescription>
          </PlanHeaderLeft>
          <PlanHeaderRight>
            <PriceStyled>
              <span>$</span>
              <h2>
                {isYearlyChecked &&
                yearlyDiscountPercentage &&
                planName.toLocaleLowerCase() !== 'business' &&
                planName.toLocaleLowerCase() !== 'freemium' ? (
                  <PriceDiscount isYearlyChecked={isYearlyChecked}>
                    {''}- {yearlyDiscountPercentage}%{''}
                  </PriceDiscount>
                ) : null}

                {price}
              </h2>
            </PriceStyled>
            <PriceDescription>{priceDescription}</PriceDescription>
          </PlanHeaderRight>
        </PricingCardHeaderStyled>
        <div style={{ margin: '1.5rem 0 1rem' }}>{children}</div>
        <FeaturesStyled>
          {features.map((feature, index) =>
            // Clear map result: avoid dot without feature.name label
            feature.name ? (
              <li key={index}>
                {feature.description ? (
                  <>
                    <Tooltip
                      className={'feature-item-styled'}
                      clickable={true}
                      place='top'
                      id={`${planName}_feature_${index}`}
                    />
                    <span
                      className={'feature-item-label-styled'}
                      style={{ cursor: 'help' }}
                      data-tooltip-content={feature.description}
                      data-tooltip-id={`${planName}_feature_${index}`}
                    >
                      {feature.name}
                    </span>
                  </>
                ) : (
                  <>{feature.name}</>
                )}
              </li>
            ) : null
          )}
        </FeaturesStyled>
      </PricingCardBox>
    </PricingCardWrapper>
  )
}
