import React, { useEffect, useMemo, useState } from 'react'
import { useFetchAllApps } from '@app/api/getAllApps'
import {
  InputSmartSelect,
  SelectValue,
} from '@components/Form/InputSmartSelect'
import { useFetchMostPopularApps } from '@app/api/getMostPopularApp'
import { useTranslation } from 'react-i18next'
import {
  getAppOptionFromAppId,
  tranformApiAppsToSelectOptions,
} from '@features/nbee/SimpleBridgeBuilderForm/fields/AppSelector/utils'
import { useFetchConnectedApps } from '@app/api/getConnectedApps'
import Fuse from 'fuse.js'
import { useField, useFormikContext } from 'formik'
import {
  AppConfigurationType,
  PartialConfiguredApp,
  BridgeFormValues,
} from 'Nbee'
import { trackEvent } from '@app/dataTracking'
import { makeNbeeTrackingParams } from '@app/dataTracking/utils'
import { useLocation } from 'react-router-dom'
import { bridgeBuilderStep1Paths, bridgeByUrlPartnersPaths } from '@app/routes'

interface AppSelectorProps {
  type: AppConfigurationType
}

export const AppSelector: React.FC<AppSelectorProps> = ({ type }) => {
  const { t } = useTranslation()
  const [appNotFound, setAppNotFound] = useState(false)

  const location = useLocation()

  const step1Paths = [...bridgeBuilderStep1Paths, ...bridgeByUrlPartnersPaths]
  const isInStep1 = step1Paths.includes(location.pathname)

  // appId field
  const inputName = `${type}.appId`
  const [appIdField, appIdMeta, appIdHelpers] =
    useField<PartialConfiguredApp['appId']>(inputName)
  const selectedAppId = appIdField.value

  // formik helpers
  const { values, setFieldValue } = useFormikContext<BridgeFormValues>()

  const { data: allApps, isLoading: isLoadingApps } = useFetchAllApps()
  const { data: mostPopular, isLoading: isLoadingMostPopular } =
    useFetchMostPopularApps({ type: type, limit: 50 })

  const { data: connectedApps, isLoading: isLoadingConnectedApps } =
    useFetchConnectedApps()

  const isLoadingAll =
    isLoadingApps || isLoadingMostPopular || isLoadingConnectedApps

  const mostPopularAppsOptions = tranformApiAppsToSelectOptions(mostPopular)
  const allAppsOptions = useMemo(
    () => tranformApiAppsToSelectOptions(allApps),
    [allApps]
  )

  const connectedAppsOptions = tranformApiAppsToSelectOptions(connectedApps)

  const defaultValue = getAppOptionFromAppId(selectedAppId, allAppsOptions)

  // every time we have a selected value (defaultValue) we store in our formik state its logo
  // so we can re-use it in case of need
  useEffect(() => {
    setFieldValue(`ui.${type}.logoUri`, defaultValue?.logoUri || '')
    setFieldValue(`ui.${type}.selectedAppName`, defaultValue?.label || '')
  }, [defaultValue])

  // sending track event every time a value is updated
  const selectedAppName =
    (allApps || []).find((a) => a.id === selectedAppId)?.name || ''
  useEffect(() => {
    if (selectedAppId && selectedAppName) {
      trackEvent({
        eventName: type === 'source' ? 'SourceSelected' : 'DestinationSelected',
        feature: 'NBEE',
        step: isInStep1 ? 'Apps' : 'BridgeChooser',
        params: {
          ...makeNbeeTrackingParams(values, type),
          // be sure we always send the just selected value and to avoid that form values are not being updated yet
          appName: selectedAppName,
          [`${type}AppId`]: selectedAppId, // computed sourceAppId or destinationAppID
          [`${type}AppName`]: selectedAppName, // computed sourceAppName or destinationAppName
        },
      })
    }
  }, [selectedAppId, selectedAppName])

  const initialValues = [
    {
      label: t('common.connectedApps'),
      options: connectedAppsOptions,
    },
    {
      label: t('common.mostPopular'),
      options: mostPopularAppsOptions,
    },
  ]

  // setting app fuzzy search with Fuse.js
  // to search with typo tollerance
  // https://fusejs.io/api/options.html
  const fuzzySearch = new Fuse(allAppsOptions, {
    isCaseSensitive: false,
    shouldSort: true,
    findAllMatches: true,
    includeMatches: true,
    includeScore: true,
    // minMatchCharLength: 3,
    threshold: 0.25, // 0 for exact match of letter and location, 1 to match everything
    distance: 30,
    // distance: 800,
    keys: ['label'],
    ignoreLocation: true, // in this way searching for `lead` will make "Lead Metadata" and "Facebook Lead Ads" at same position
    sortFn: (a, b) => {
      // this is the order that comes from original array (index position from 0 to array.length)
      const aPopularityOrder = a.idx + 1
      const bPopularityOrder = b.idx + 1

      // this is the score assigned by fuseJS
      const aScore = a.score
      const bScore = b.score

      // this is our computed score
      // it means we have our internal score calculated from popularity (1 - 1 / popularityOrder) and we add it tp the fuzzy search score
      // computed scores closest to zero will appear first
      const aComputedOrder = 1 - 1 / aPopularityOrder + aScore
      const bComputedOrder = 1 - 1 / bPopularityOrder + bScore

      return aComputedOrder < bComputedOrder ? -1 : 1
    },
  })

  // this function will be used to filter between all apps
  const filterResults = (pattern: string) =>
    fuzzySearch.search(pattern).slice(0, 40)

  // react-select option to load new options with async search
  const onAppSearch = (inputValue: string): Promise<SelectValue[]> =>
    new Promise((resolve) => {
      const fuzzyResults = filterResults(inputValue)
      const flattenResults = fuzzyResults.map(({ item }) => item)
      resolve(flattenResults)
      if (flattenResults.length) {
        setAppNotFound(false)
      }
      if (!flattenResults.length && !appNotFound) {
        setAppNotFound(true)
        trackEvent({
          eventName:
            type === 'source' ? 'SourceNotFound' : 'DestinationNotFound',
          step: isInStep1 ? 'Apps' : 'BridgeChooser',
          feature: 'NBEE',
          params: {
            ...makeNbeeTrackingParams(values, type),
            custom: {
              searchAppName: inputValue,
            },
          },
        })
      }
    })

  // we set a render key to force component in sync when is in edit
  const renderKey = defaultValue ? type + defaultValue.value : undefined

  const isLoadingApi =
    values.ui && values.ui[type] && values.ui[type]!.isLoadingApi

  return (
    <div>
      <InputSmartSelect
        placeholder={t('nbee.bridgeBuilder.selectApp')}
        isLoading={isLoadingAll}
        isDisabled={isLoadingApi || values.ui?.isBridgeEnabled}
        defaultValue={defaultValue}
        initialValues={initialValues}
        isClearable={false}
        // async search
        loadOptions={onAppSearch}
        // enforcing default value as key will force componente re-rending
        // when async default value arrives
        key={renderKey}
        onSelect={(selectedApp) => {
          if (Array.isArray(selectedApp)) {
            return
          }

          const newValue = selectedApp
            ? parseInt(`${selectedApp.value}`, 10)
            : undefined

          // update formik value
          appIdHelpers.setValue(newValue)
        }}
        name={inputName}
        onBlur={() => {
          appIdHelpers.setTouched(true)
        }}
        $status={
          appIdMeta.touched && appIdMeta.error
            ? {
                error: appIdMeta.error,
              }
            : undefined
        }
      />
    </div>
  )
}
