import React, { FocusEventHandler } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import {
  CustomControl,
  CustomIndicatorsContainer,
  CustomMenu,
  CustomOption,
  CustomSingleValue,
  CustomValueContainer,
  MenuList,
} from './ComponentOverrides'
import { customStyles, greyStyles, SmartSelectWrapper } from './styled'
import {
  InputFeedback,
  InputFeedbackStatus,
} from '@components/Basic/InputFeedback'
import { useIsLabelTooLong } from '@components/Form/InputSmartSelect/Tooltip'

export type GroupedSelectValues = { label: string; options: SelectValue[] }[]

export type SelectValues = {
  sourceFields: SelectValue[]
  formula: SelectValue[]
}

export type SelectValue = {
  value: string | number
  label: string
  logoUri?: string
  dropdownPrimaryLabel?: string
  secondaryLabel?: string
  icon?: 'new' | 'waiting'
  isVerified?: boolean
  type?: string
  index?: number
  [key: string]: any
}

export interface SmartSelectProps {
  initialValues: GroupedSelectValues | SelectValue[]
  defaultValue?: SelectValue | SelectValue[]
  placeholder?: string
  isLoading?: boolean
  isClearable?: boolean
  isDisabled?: boolean
  secondaryStyles?: boolean
  isSearchable?: boolean
  isMulti?: boolean
  noOptionsMessageText?: string
  onSelect: (value: SelectValue | SelectValue[]) => void
  addNewOptionButton?: {
    label: string
    onClick: () => void
  }
  addActionClick?: {
    label: string
    onClick: () => void
  }
  onInputChange?: (value: string) => void
  hasCustomSearch?: boolean
  loadOptions?: (
    inputValue: string
  ) => Promise<GroupedSelectValues | SelectValue[]>
  $status?: InputFeedbackStatus
  onBlur?: FocusEventHandler<HTMLInputElement>
  name?: string
  menuIsOpen?: boolean
  noIcon?: boolean
  tooltipMessage?: string
  extraLabel?: boolean
  emailValidation?: boolean
  keyDown?: (e: any) => any
  isVerified?: boolean
  defaultLabel?: string
  floatingLabel?: string
  upDownIconsStyle?: boolean
  fieldIsUnavailable?: boolean
  largeUpDownIconsStyle?: boolean
  isFormulas?: boolean
  'data-testid'?: string
}

export const InputSmartSelect: React.FC<SmartSelectProps> = ({
  initialValues,
  isClearable = true,
  onSelect,
  placeholder,
  defaultValue,
  isSearchable = false,
  noOptionsMessageText,
  addNewOptionButton,
  onInputChange,
  hasCustomSearch,
  isLoading,
  loadOptions,
  isMulti,
  isDisabled,
  $status,
  onBlur,
  name,
  menuIsOpen,
  noIcon,
  tooltipMessage,
  addActionClick,
  extraLabel,
  secondaryStyles,
  emailValidation,
  keyDown,
  isVerified,
  isFormulas,
  defaultLabel,
  floatingLabel,
  upDownIconsStyle,
  fieldIsUnavailable,
  largeUpDownIconsStyle,
  'data-testid': testId, // destructuring data-testid prop
}) => {
  const { wrapperEl, selectedSingleValueEl, isLabelTooLong } =
    useIsLabelTooLong({
      defaultValue: defaultValue as SelectValue,
    })

  // use this object to pass extra props and read them inside custom components (overrides)
  const extraProps = {
    addNewOptionButton: addNewOptionButton,
    addActionClick: addActionClick,
    extraLabel: extraLabel,
    noIcon: noIcon,
    emailValidation: emailValidation,
    isVerified: isVerified,
    defaultLabel: defaultLabel,
    floatingLabel: floatingLabel,
    upDownIconsStyle: upDownIconsStyle,
    largeUpDownIconsStyle: largeUpDownIconsStyle,
    selectedSingleValueEl: selectedSingleValueEl,
    isFormulas: isFormulas,
    // this is the selected value to be displayed when text is too long
    tooltipInternalLabel: isLabelTooLong
      ? (defaultValue as SelectValue).label
      : undefined,
    tooltipExternalMessage: tooltipMessage,
    fieldIsUnavailable: fieldIsUnavailable,
  }

  const noDropdownIndicator =
    isSearchable || loadOptions
      ? { DropdownIndicator: () => null, IndicatorSeparator: () => null }
      : {}

  return (
    <SmartSelectWrapper
      ref={wrapperEl}
      isLoading={isLoading}
      data-testid={testId}
    >
      {loadOptions ? (
        <AsyncSelect
          components={{
            Option: CustomOption,
            SingleValue: CustomSingleValue,
            Control: CustomControl,
            Menu: CustomMenu,
            MenuList: MenuList,
            ValueContainer: CustomValueContainer,
            IndicatorsContainer: CustomIndicatorsContainer,
            ...noDropdownIndicator,
          }}
          styles={secondaryStyles ? greyStyles : customStyles}
          isClearable={isClearable}
          // always searchable when async
          isSearchable
          defaultOptions={initialValues}
          defaultValue={defaultValue}
          onChange={(selectedValue) => {
            onSelect(selectedValue as SelectValue)
          }}
          loadOptions={loadOptions}
          placeholder={isLoading ? 'Loading...' : placeholder}
          onBlur={onBlur}
          name={name}
          menuIsOpen={menuIsOpen}
          isDisabled={isLoading || isDisabled}
          {...extraProps}
        />
      ) : (
        <Select
          menuIsOpen={menuIsOpen}
          options={initialValues}
          defaultValue={defaultValue}
          components={{
            Option: CustomOption,
            SingleValue: CustomSingleValue,
            Control: CustomControl,
            Menu: CustomMenu,
            MenuList: MenuList,
            ValueContainer: CustomValueContainer,
            IndicatorsContainer: CustomIndicatorsContainer,
            ...noDropdownIndicator,
          }}
          styles={secondaryStyles ? greyStyles : customStyles}
          isClearable={isClearable}
          placeholder={isLoading ? 'Loading...' : placeholder}
          noOptionsMessage={
            noOptionsMessageText ? () => noOptionsMessageText : undefined
          }
          isDisabled={isLoading || isDisabled}
          isSearchable={isSearchable || hasCustomSearch}
          onChange={(selectedValue) => {
            onSelect(selectedValue as SelectValue)
          }}
          onInputChange={(newValue) => {
            if (onInputChange) {
              onInputChange(newValue)
            }
          }}
          filterOption={hasCustomSearch ? () => true : undefined}
          isMulti={isMulti}
          onBlur={onBlur}
          name={name}
          onKeyDown={keyDown}
          {...extraProps}
        />
      )}
      {$status && <InputFeedback $status={$status} />}
    </SmartSelectWrapper>
  )
}
