import React from 'react'
import { FakeInputStyled } from './styled'

export interface FakeInputProps {
  required?: boolean
  text?: string
}

export const FakeInput: React.FC<FakeInputProps> = ({ required, text }) => {
  return (
    <FakeInputStyled required={required}>
      <p>{text}</p>
    </FakeInputStyled>
  )
}
