import React, { useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import { BridgeFormValues, MappedField } from 'Nbee'

import { FakeInput } from '@components/Basic/FakeInput'
import {
  InputFeedback,
  InputFeedbackStatus,
} from '@components/Basic/InputFeedback'
import { StateIcon, ValidationStates } from '@components/Basic/StateIcon'
import { Dropdown } from '@components/Dropdown'
import { DropdownMenuItem } from '@components/Dropdown/DropdownMenuItem'
import { InputField } from '@components/Form/InputField'
import {
  InputSmartSelect,
  SelectValue,
} from '@components/Form/InputSmartSelect'
import { FormulaModal } from '@features/nbee/FieldsMappingForm/FormulaModal'

import { RowFeedback, RowStyled, RowWrapper } from './styled'
import { MdDelete, MdEdit } from 'react-icons/md'
import {
  AiFillCaretDown,
  AiOutlineFunction,
  AiOutlineUnorderedList,
} from 'react-icons/ai'
import { RiText } from 'react-icons/ri'
import { ApiUserModuleItem } from 'BackendApi'
import { StarterLabel } from '@features/nbee/AddFilterButton/styled'
import { FaLock } from 'react-icons/fa'
import { DropdownIconWrapper } from '@components/Dropdown/styled'
import { minimumPlanName } from '@features/nbee/FieldsMappingForm'
import { useNavigate } from 'react-router-dom'
import { useUpdateBridgeToPricing } from '@features/nbee/utils'

interface RowProps {
  index: number
  selectOptions: SelectValue[]
  formulaUserModule?: ApiUserModuleItem | undefined
}

type ErrorObject = {
  [key: string]: any
}

// function to flatten the hasError object and extract the errors from the params
const flattenErrors = (errorObject: ErrorObject): string[] => {
  return Object.entries(errorObject).reduce(
    (messages: string[], [key, value]) => {
      if (typeof value === 'object') {
        // when we have a formula,
        // checks if we need to retrieve the error message
        // from the values in the param array
        const subErrors = flattenErrors(value as ErrorObject)
        return [...messages, ...subErrors]
      } else {
        return [...messages, value as string]
      }
    },
    []
  )
}

export const FieldMappingRow: React.FC<RowProps> = ({
  selectOptions,
  index,
  formulaUserModule,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { values } = useFormikContext<BridgeFormValues>()

  const fieldName = `fieldsMapping.${index}`
  const [field, meta, helpers] = useField<MappedField>(fieldName)
  const fieldValue = field.value
  const bridgeId = values?.ui?.bridgeId

  const [isModalOpen, toggleIsModalOpen] = useState<boolean>(false)
  const handleOpenModal = () => toggleIsModalOpen(true)
  const handleCloseModal = () => {
    toggleIsModalOpen(false)
  }

  const hasFormula = !!fieldValue.formula?.id && !!fieldValue.formula?.params[0]

  const userHasFormulaEnabled = formulaUserModule?.enabled

  const moduleFormulaId = formulaUserModule?.id
  const minimumPlanRequired = formulaUserModule?.minimumPlan

  const planLabelName =
    minimumPlanRequired && minimumPlanName[minimumPlanRequired.toString()]
      ? minimumPlanName[minimumPlanRequired.toString()].toUpperCase()
      : ''

  const updateBridgeToPricing = useUpdateBridgeToPricing(
    bridgeId,
    moduleFormulaId,
    minimumPlanRequired
  )

  const picklistOptions: SelectValue[] = (fieldValue.picklist || []).map(
    (item) => {
      return {
        value: item.id,
        label: item.text,
      }
    }
  )

  const sourceFieldOptions = selectOptions.filter(
    (option) => option.type === 'sourceField'
  )
  const formulaOptions = selectOptions.filter(
    (option) => option.type === 'formula' || 'mappedFormula'
  )

  const defaultValueForPicklist = picklistOptions.find(
    (o) => o.value === fieldValue.sourceFieldId
  )

  const defaultValueForSelect = selectOptions.find(
    (o) => o.value === fieldValue.sourceFieldId
  )

  const defaultValueForFormulas = formulaOptions.find(
    (o) => o.value === fieldValue.formula?.id && o.index === index
  )

  const hasValue =
    fieldValue.destinationText ||
    fieldValue.sourceFieldId ||
    (fieldValue.formula?.id && fieldValue.formula?.params[0]?.values)
  const hasError = meta.error as Partial<MappedField> | undefined // we are using a custom yup schema

  const errorStatusMessage = hasError && flattenErrors(hasError).join(', ')

  const errorStatus: InputFeedbackStatus | undefined =
    // meta.touched &&
    hasError
      ? {
          error: errorStatusMessage,
        }
      : undefined

  const validationIconState: ValidationStates = hasError
    ? 'error'
    : hasValue
    ? 'success'
    : 'default'

  /*
  const handleNotAvailableFeatureRequest = (
    feature: 'formula' | 'textArea'
  ) => {
    trackEvent({
      eventName:
        feature === 'formula'
          ? 'AddFormulaComingSoonClicked'
          : 'AddTextareaComingSoonClicked',
      feature: 'NBEE',
      step: 'FieldsMapping',
      params: {
        ...makeNbeeTrackingParams(values),
      },
    })

    onNotAvailableFeatureRequest()
  }
  */
  const hasExtraLabel = (enabled?: string[], disabled?: string[]): boolean => {
    // enableExtraLabel(['currencyCode']
    if (enabled?.length) {
      return !!enabled.find((item) => item === fieldValue.destinationFieldId)
    } else {
      return true
    }
  }

  return (
    <RowWrapper>
      <RowStyled>
        <FakeInput
          required={fieldValue.isRequired}
          text={fieldValue.destinationFieldLabel}
        />
        <StateIcon state={validationIconState} />
        {fieldValue.destinationFieldType === 'custom' ? (
          <InputSmartSelect
            initialValues={picklistOptions}
            extraLabel={hasExtraLabel()}
            defaultValue={defaultValueForPicklist}
            key={defaultValueForPicklist?.value || 'no-value'}
            noIcon
            isDisabled={values.ui?.isBridgeEnabled}
            isClearable={false}
            onSelect={(selectedValue) => {
              const singleValue = selectedValue as SelectValue
              //  if selected value is the same as the initial value, so we don't change the type
              // so we don't risk to override the 'auto'
              const type =
                singleValue.value === meta.initialValue?.sourceFieldId
                  ? meta.initialValue.mappingType
                  : 'manual'
              helpers.setValue({
                ...fieldValue,
                mappingType: type,
                destinationText: undefined,
                sourceFieldId: singleValue?.value.toString(),
              })
            }}
          />
        ) : fieldValue.destinationFieldType === 'source' ? (
          <InputSmartSelect
            key={defaultValueForSelect?.value || 'no-value'}
            initialValues={sourceFieldOptions}
            defaultValue={defaultValueForSelect}
            noIcon
            isDisabled={values.ui?.isBridgeEnabled}
            isClearable={false}
            largeUpDownIconsStyle
            onSelect={(selectedValue) => {
              const singleValue = selectedValue as SelectValue
              //  if selected value is the same as the initial value, so we don't change the type
              // so we don't risk to override the 'auto'
              const type =
                singleValue.value === meta.initialValue?.sourceFieldId
                  ? meta.initialValue.mappingType
                  : 'manual'
              helpers.setValue({
                ...fieldValue,
                mappingType: type,
                destinationText: undefined,
                sourceFieldId: singleValue?.value.toString(),
              })
            }}
          />
        ) : fieldValue.destinationFieldType === 'formula' ? (
          <InputSmartSelect
            key={
              defaultValueForFormulas?.label ||
              `no-value-${fieldValue.formula?.id}-${index}`
            }
            isDisabled={hasFormula}
            defaultValue={defaultValueForFormulas}
            initialValues={formulaOptions.filter((f) => f.secondaryLabel)}
            isClearable={false}
            onSelect={(selectedValue) => {
              const singleValue = selectedValue as SelectValue

              // If the selected formula is different from the one currently selected (if any), empties out params
              if (singleValue?.value !== fieldValue.formula?.id) {
                helpers.setValue({
                  ...fieldValue,
                  sourceFieldId: undefined,
                  mappingType: 'manual',
                  formula: {
                    id: `${singleValue?.value}`,
                    params: [],
                  },
                })
              }
              handleOpenModal()
            }}
            isFormulas
            largeUpDownIconsStyle
          />
        ) : (
          <InputField
            name={fieldName}
            type={'text'}
            value={fieldValue.destinationText || ''}
            onChange={({ target: { value } }) => {
              //  if selected value is the same as tge initial value, so we don't change the type
              // so we don't risk to override the 'auto'
              const type =
                value === meta.initialValue?.destinationText
                  ? meta.initialValue.mappingType
                  : 'manual'
              helpers.setValue({
                ...fieldValue,
                mappingType: type,
                sourceFieldId: undefined,
                destinationText: value,
              })
            }}
            onBlur={field.onBlur}
            disabled={values.ui?.isBridgeEnabled}
          />
        )}
        {!values.ui?.isBridgeEnabled ? (
          <div style={{ display: 'flex' }}>
            <Dropdown $direction={'right'}>
              {fieldValue.picklist && fieldValue.picklist.length > 0 ? (
                <DropdownMenuItem
                  isActive={fieldValue.destinationFieldType === 'custom'}
                  onClick={() => {
                    helpers.setValue({
                      ...fieldValue,
                      sourceFieldId: undefined,
                      destinationText: undefined,
                      destinationFieldType: 'custom',
                      mappingType: 'manual',
                      formula: null,
                    })
                  }}
                >
                  <AiOutlineUnorderedList />
                  {t('nbee.fieldsMapping.dropdown.addPicklist')}
                </DropdownMenuItem>
              ) : null}

              <DropdownMenuItem
                isActive={fieldValue.destinationFieldType === 'text'}
                onClick={() => {
                  helpers.setValue({
                    ...fieldValue,
                    sourceFieldId: undefined,
                    destinationText: '',
                    destinationFieldType: 'text',
                    mappingType: 'manual',
                    formula: null,
                  })
                }}
              >
                <RiText />
                {t('nbee.fieldsMapping.dropdown.addText')}
              </DropdownMenuItem>

              <DropdownMenuItem
                isActive={fieldValue.destinationFieldType === 'source'}
                onClick={() => {
                  helpers.setValue({
                    ...fieldValue,
                    sourceFieldId: undefined,
                    destinationText: undefined,
                    destinationFieldType: 'source',
                    mappingType: 'manual',
                    formula: null,
                  })
                }}
              >
                <AiFillCaretDown />
                {t('nbee.fieldsMapping.dropdown.addValue')}
              </DropdownMenuItem>
              {hasFormula ? (
                <>
                  <DropdownMenuItem
                    isDisabled={!userHasFormulaEnabled}
                    onClick={() => {
                      handleOpenModal()
                    }}
                  >
                    <MdEdit style={{ color: '#41a6dc' }} />
                    {t('nbee.fieldsMapping.dropdown.editFormula')}
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    isDisabled={!userHasFormulaEnabled}
                    onClick={() => {
                      helpers.setValue({
                        ...fieldValue,
                        sourceFieldId: undefined,
                        destinationText: undefined,
                        destinationFieldType: 'formula',
                        mappingType: 'manual',
                        formula: null,
                      })
                    }}
                  >
                    <MdDelete />
                    {t('nbee.fieldsMapping.dropdown.deleteFormula')}
                  </DropdownMenuItem>
                </>
              ) : (
                <DropdownMenuItem
                  isActive={fieldValue.destinationFieldType === 'formula'}
                  onClick={() => {
                    if (!userHasFormulaEnabled) {
                      updateBridgeToPricing()
                    } else {
                      helpers.setValue({
                        ...fieldValue,
                        sourceFieldId: undefined,
                        destinationText: undefined,
                        destinationFieldType: 'formula',
                        mappingType: 'manual',
                        formula: {
                          id: ``,
                          params: [],
                        },
                      })
                    }
                  }}
                >
                  <AiOutlineFunction />
                  {t('nbee.fieldsMapping.dropdown.addFormula')}
                  {!userHasFormulaEnabled && (
                    <DropdownIconWrapper>
                      <StarterLabel small>
                        <FaLock /> {planLabelName}
                      </StarterLabel>
                    </DropdownIconWrapper>
                  )}
                </DropdownMenuItem>
              )}

              <DropdownMenuItem
                onClick={() => {
                  helpers.setValue({
                    ...fieldValue,
                    sourceFieldId: undefined,
                    destinationText: undefined,
                    destinationFieldType: 'source',
                    mappingType: 'manual',
                    formula: null,
                  })
                }}
              >
                <MdDelete style={{ color: '#db3c3c' }} />
                <span style={{ color: '#db3c3c' }}>
                  {t('nbee.fieldsMapping.dropdown.unmapField')}
                </span>
              </DropdownMenuItem>
            </Dropdown>
            <div>
              <MdDelete
                onClick={() => {
                  helpers.setValue({
                    ...fieldValue,
                    sourceFieldId: undefined,
                    destinationText: undefined,
                    destinationFieldType: 'source',
                    mappingType: 'manual',
                    formula: null,
                  })
                }}
                style={{
                  color: '#db3c3c',
                  cursor: 'pointer',
                  marginLeft: '.05rem',
                  visibility:
                    fieldValue.sourceFieldId || fieldValue?.formula?.id
                      ? 'visible'
                      : 'hidden',
                }}
              />
            </div>
          </div>
        ) : null}
        {errorStatus && (
          <RowFeedback>
            <InputFeedback $status={errorStatus} />
          </RowFeedback>
        )}
        {isModalOpen && (
          <FormulaModal
            formulaId={fieldValue.formula?.id || ''}
            modalTitle={!hasFormula ? 'Add' : 'Edit'}
            isOpen={isModalOpen}
            onCloseModal={handleCloseModal}
            index={index}
            sourceFieldOptions={sourceFieldOptions}
          />
        )}
      </RowStyled>
    </RowWrapper>
  )
}
