import React from 'react'
import {
  CheckoutPrePrice,
  CeckoutTotalPrice,
  CheckoutTrialLimitsDisclaimer,
} from './styled'
import { CheckoutTitle } from '@features/checkout/CheckoutTitle'
import { Button } from '@components/Basic/ButtonNbe'
import { useTranslation } from 'react-i18next'

interface Props {
  amountToPay: string
  buttonLabel: string
  billingCircle: string
  nextInvoiceAmount?: string
  nextBillingDate?: string
  onSubmit?: () => void
  isLoading?: boolean
  isTrialAvailable?: boolean
  isProrated?: boolean
}

export const CheckoutFooter: React.FC<Props> = ({
  billingCircle,
  nextBillingDate,
  amountToPay,
  buttonLabel,
  onSubmit,
  isLoading,
  isTrialAvailable,
  nextInvoiceAmount,
  isProrated,
}) => {
  const { t } = useTranslation()
  return (
    <>
      {isTrialAvailable || isProrated ? (
        <CheckoutPrePrice data-id={'checkout-pre-price'}>
          <p>
            {t('billing.checkout.paymentOnDate', {
              nextBillingDate: nextBillingDate,
            })}
          </p>
          <p className={'text-right'}>
            <b>{nextInvoiceAmount}</b>
            <span style={{ opacity: 0.5 }}> {`per ${billingCircle}`}</span>
          </p>
        </CheckoutPrePrice>
      ) : null}
      {isTrialAvailable ? (
        <CheckoutTrialLimitsDisclaimer>
          {t('billing.checkout.trialLimitDisclaimer')}
        </CheckoutTrialLimitsDisclaimer>
      ) : null}
      <hr style={{ margin: '20px 0' }} />
      <CeckoutTotalPrice data-id={'checkout-total-price'}>
        <CheckoutTitle>{t('billing.checkout.todayYouPay')}</CheckoutTitle>
        <p>{amountToPay}</p>
      </CeckoutTotalPrice>
      <Button
        type={onSubmit ? 'button' : 'submit'}
        $size={'large'}
        $variant={'blue'}
        $loading={isLoading}
        disabled={isLoading}
        onClick={() => {
          if (onSubmit) {
            onSubmit()
          }
        }}
      >
        {buttonLabel}
      </Button>
    </>
  )
}
