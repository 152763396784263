import React from 'react'

import { InputField } from '@components/Form/InputField'
import {
  TestMapperField,
  TestMapperFieldLabel,
  TestMapperWrapper,
} from '@features/nbee/FieldsMappingForm/SendTestLeadSubForm/styled'
import { useTranslation } from 'react-i18next'
import { ApiTestableField } from 'BackendApi'

interface Props {
  fieldsToRender: ApiTestableField[]
  setFieldValue: (field: string, value: ApiTestableField[]) => void
  fieldMappingTestingFieldName: string
}

export const SendTestLeadSubForm: React.VFC<Props> = ({
  fieldsToRender,
  setFieldValue,
  fieldMappingTestingFieldName,
}) => {
  const { t } = useTranslation()

  return (
    <TestMapperWrapper>
      {!fieldsToRender.length ? (
        <div>{t('nbee.fieldsMapping.noFieldsToTestFound')}</div>
      ) : (
        <>
          <h4 className={'text-center'}>{t('nbee.fieldsMapping.testTitle')}</h4>
          {fieldsToRender.map((field) => (
            <TestMapperField key={field.id}>
              <div>
                <TestMapperFieldLabel>{field.label}</TestMapperFieldLabel>
              </div>
              <InputField
                type={'text'}
                name={field.id}
                value={field.value || ''}
                onChange={({ target: { value } }) => {
                  setFieldValue(
                    fieldMappingTestingFieldName,
                    fieldsToRender.map((oldField) =>
                      oldField.id === field.id
                        ? {
                            ...oldField,
                            value: value,
                          }
                        : oldField
                    )
                  )
                }}
              />
            </TestMapperField>
          ))}
        </>
      )}
    </TestMapperWrapper>
  )
}
