import React, { useEffect, useState } from 'react'
import ReactModal, { Styles } from 'react-modal'
import styled, { css } from 'styled-components'
import { IoCloseOutline } from 'react-icons/io5'
import { defaultTheme } from '@app/styles/theme/default'
import { ReactComponent as MinimizeIcon } from '@assets/images/minimize.svg'
import { ReactComponent as CloseBlackIcon } from '@assets/images/close_black.svg'
import { ReactComponent as ExpandIcon } from '@assets/images/expand.svg'

interface ModalProps {
  isOpen: boolean
  onCloseModal: () => void
  hideCloseButton?: boolean
  isTransparent?: boolean
  isResizable?: boolean
  noPadding?: boolean
  modalTitle?: string
  isWide?: boolean
  children?: React.ReactNode
}

const customStyles: Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    margin: '0',
    width: '600px',
    borderRadius: '8px',
    boxShadow: '2px 2px 15px 0px rgba(31,31,31,0.28)',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
  },
  overlay: {
    zIndex: defaultTheme.zIndex.modal,
  },
}

const noPaddingStyles: Styles = {
  content: {
    ...customStyles.content,
    borderRadius: '5px',
    boxShadow: undefined,
    minWidth: '570px',
    width: 'auto',
    height: '65vh',
    maxHeight: '75vh',
    maxWidth: '95vw',
    padding: 0,
    margin: 0,
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: 'rgba(94,94,94,0.6)',
    zIndex: defaultTheme.zIndex.modal,
  },
}

const secondaryCustomStyles: Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    margin: '0',
    width: '600px',
    border: 'none',
    backgroundColor: 'transparent',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: defaultTheme.zIndex.modal,
  },
}

const minimizeCustomStyles: Styles = {
  content: {
    top: '60%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    margin: '0',
    width: '40%',
    padding: '0',
    borderRadius: '6px',
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.4)',
    backgroundColor: '#fff',
    transform: 'translate(20%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
}

const expandCustomStyles: Styles = {
  content: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    padding: '0',
    borderRadius: '6px',
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.4)',
    backgroundColor: '#fff',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
}

const ResizeWrapper = styled.div`
  background-color: #41a6dc;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;

  p {
    margin: 0;
    flex: 1;
    font-weight: 600;
    color: #fff;
  }

  svg {
    cursor: pointer;
  }
`

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 3rem;
`

const CloseIcon = styled.div<Pick<ModalProps, 'isTransparent' | 'noPadding'>>`
  text-align: right;
  cursor: pointer;
  ${({ isTransparent, noPadding }) =>
    isTransparent || noPadding
      ? css`
          font-size: 2rem;
          background-color: #f8f8f8;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1rem;
          border-radius: 0 10%;
        `
      : null}
  ${({ noPadding }) =>
    noPadding &&
    css`
      margin: 0;
    `}
`

/* const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;

  h3 {
    font-size: 1.35rem;
    padding-left: 1.5rem;
    padding-top: 1.5rem;
  }
` */

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  children,
  onCloseModal,
  hideCloseButton,
  isTransparent,
  isResizable,
  noPadding,
  // modalTitle,
  isWide,
}) => {
  const [isExpand, setIsExpand] = useState(false)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const wideCustomStyles: Styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      margin: '0',
      width: windowWidth <= 768 ? '80%' : '750px',
      padding: windowWidth <= 768 ? '0' : '1rem',
      borderRadius: '6px',
      boxShadow: '2px 2px 15px 0px rgba(31,31,31,0.28)',
      backgroundColor: '#fff',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
      backgroundColor: 'rgba(94,94,94,0.6)',
      zIndex: defaultTheme.zIndex.modal,
    },
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      style={
        isResizable && !isExpand
          ? minimizeCustomStyles
          : isResizable && isExpand
          ? expandCustomStyles
          : isTransparent
          ? secondaryCustomStyles
          : noPadding
          ? noPaddingStyles
          : isWide
          ? wideCustomStyles
          : customStyles
      }
      preventScroll={false}
    >
      {/* {noPadding && modalTitle ? (
        <ModalHeader>
          <h3>{modalTitle}</h3>
          <CloseIcon noPadding={noPadding} onClick={onCloseModal}>
            <IoCloseOutline />
          </CloseIcon>
        </ModalHeader>
      ) : null} */}

      {!hideCloseButton && !noPadding ? (
        <CloseIcon isTransparent={isTransparent} onClick={onCloseModal}>
          <IoCloseOutline />
        </CloseIcon>
      ) : null}

      {isResizable ? (
        <>
          <ResizeWrapper>
            <p>Getting started video</p>
            <IconsWrapper>
              {isExpand ? (
                <MinimizeIcon onClick={() => setIsExpand(false)} />
              ) : (
                <ExpandIcon onClick={() => setIsExpand(true)} />
              )}
              <CloseBlackIcon onClick={onCloseModal} />
            </IconsWrapper>
          </ResizeWrapper>
        </>
      ) : null}
      {children}
    </ReactModal>
  )
}

ReactModal.setAppElement('#root')
