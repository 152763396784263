import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { ReactComponent as CardBoxImage } from '@assets/images/card_payments.svg'
import { SecurePaymentLogo } from './SecurePaymentLogo'

export const Wrapper = styled.div<{ hasPadding?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border-radius: 8px;
  gap: 2rem;
  background: transparent;

  padding: ${({ hasPadding }) => (hasPadding ? '1rem' : '0')};
  @media (min-width: 500px) {
    padding: ${({ hasPadding }) => (hasPadding ? '2rem' : '0')};
  }

  .full-width {
    width: 100%;
  }

  h2 {
    font-size: 1.7rem;
    margin-bottom: 2rem;
  }
`

const CardInfo = styled.div`
  flex: 1;
`
const CardBoxASideWrapper = styled.div`
  width: 30%;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: 600px) {
    display: none;
  }
`
const CardBoxASideImageWrapper = styled.div`
  svg {
    display: block;
    width: 75%;
    height: auto;
  }
`

interface Props {
  showSecurePaymentLogo?: boolean
  title?: string
  hasPadding?: boolean
  children?: ReactNode
}

export const BoxWrapperWithSideImage: React.FC<Props> = ({
  children,
  showSecurePaymentLogo,
  title,
  hasPadding,
}) => {
  return (
    <Wrapper hasPadding={hasPadding}>
      <CardInfo>
        {title && <h2>{title}</h2>}
        {children}
      </CardInfo>
      <CardBoxASideWrapper>
        <CardBoxASideImageWrapper>
          <CardBoxImage />
        </CardBoxASideImageWrapper>
        {showSecurePaymentLogo && <SecurePaymentLogo />}
      </CardBoxASideWrapper>
    </Wrapper>
  )
}
