import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

interface CheckoutTitleProps {
  onClick?: () => void
  isOpen?: boolean
  children?: ReactNode
}

const CheckoutTitleWrapper = styled.div<{ isClickable?: boolean }>`
  display: inline-block;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
`

const TitleStyled = styled.div`
  display: flex;
  align-items: center;

  h2 {
    margin: 0 12px 0 0;
    font-size: 1rem;
    font-weight: 600;
  }
`

export const CheckoutTitle: React.FC<CheckoutTitleProps> = ({
  onClick,
  isOpen = false,
  children,
}) => {
  return (
    <CheckoutTitleWrapper onClick={onClick} isClickable={!!onClick}>
      <TitleStyled>
        <h2>{children}</h2>
        {isOpen && onClick ? (
          <FiChevronUp />
        ) : !isOpen && onClick ? (
          <FiChevronDown />
        ) : null}
      </TitleStyled>
    </CheckoutTitleWrapper>
  )
}
