import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import {
  validatePersistentBbuData,
  retryButtonPartnerLink,
  userStatusForSurvey,
} from '@app/pages/BridgeByUrlCallback/utils'
import queryString from 'query-string'
import { Button } from '@components/Basic/Button'
import { useGenericBbuAuth } from '@app/pages/BridgeByUrlCallback/useGenericBbuAuth'
import { BbuCallbackRouteParams, PersistentBbuData } from 'LeadsBridgeApp'
import { PersistentLocalStorageKeys } from '@app/enums/persistentLocalStorageKeys'
import { FastAppSurvey } from '@components/FastAppSurvey'
import { endpoints, getApiErrorMessage } from '@app/api/config'
import { Message } from '@components/Basic/Message'
import { Container } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { appRoutes } from '@app/routes'
import { trackEvent } from '@app/dataTracking'

interface Props {
  isMountedInLoggedRoute: boolean
}

export const BridgeByUrlCallback: React.FC<Props> = ({
  isMountedInLoggedRoute,
}) => {
  // can user reach this also when logged in?
  const { partnerFullName } = useParams<BbuCallbackRouteParams>()
  const location = useLocation()
  const navigate = useNavigate()
  const persistentBbuData: PersistentBbuData = JSON.parse(
    window.sessionStorage.getItem(
      PersistentLocalStorageKeys.bbuPersistentData
    ) || '{}'
  )
  const { t } = useTranslation()

  const authLbCode = queryString.parse(location.search).lb_code as
    | string
    | undefined

  // ui states
  const [isLoading, setIsLoading] = useState(true)
  const [progressStep, setProgressStep] = useState<0 | 1 | 2>(0)
  const [loadingText, setLoadingText] = useState(
    'We are connecting your account'
  )
  const [hasJustCompletedSurvey, setHasJustCompletedSurvey] = useState(false)
  const [genericApiError, setGenericApiError] = useState('')

  // before using data from local storage, it's always better to validate them
  const validPersistentBbuData = validatePersistentBbuData(persistentBbuData)
    ? persistentBbuData
    : null

  const errorMissingStartingData = !validPersistentBbuData
    ? t('bbu.callback.missingPersitentData')
    : !authLbCode
    ? t('bbu.callback.missingAuthLbCode')
    : null

  // when user is not logged in
  const { userStatus, apiError } = useGenericBbuAuth({
    provider: partnerFullName,
    authCode: authLbCode,
    requestUri: validPersistentBbuData?.requestUri,
    bbuData: validPersistentBbuData,
  })

  const persistentUserStatus = localStorage.getItem(
    PersistentLocalStorageKeys.bbuPersistentUserStatus
  )

  const surveyNeeded =
    userStatusForSurvey.includes(persistentUserStatus || '') ||
    userStatusForSurvey.includes(userStatus || '')

  const handleFinalStep = () => {
    // As decided in SB-782 we take the user to nbee step1 instead of calling setupBridge
    // https://leadsbridge.atlassian.net/browse/SB-782
    window.sessionStorage.removeItem(
      PersistentLocalStorageKeys.bbuPersistentData
    )
    try {
      const bbuUrl = new URL(validPersistentBbuData!.requestUri).pathname
      navigate(bbuUrl)
    } catch {
      navigate(appRoutes.nbeeStep1New.makeUrl())
    }
  }

  useEffect(() => {
    if (hasJustCompletedSurvey) {
      // if user has just completed survay, surveyNeeded will mutate and we don't want to proceed with this useEffect
      return
    }

    if (!isMountedInLoggedRoute) {
      // we want to be sure this component has been mounted in it's final state, to prevent firing api requests twice
      // note that we can't rely on redux, because redux store will be updated before the remount of this component in the signed-in routes
      return
    }

    if (surveyNeeded) {
      console.log('User is logged in but survey is needed')
      trackEvent({
        eventName: 'FastSurveyShown',
        feature: 'Authentication',
        step: 'Signup',
      })
      setProgressStep(1)
      setIsLoading(false)
    } else {
      console.log(
        'User is logged in and already has own email (survey is not needed), so we can call handleOauth (private endpoint) + setupBridge'
      )
      setProgressStep(2)
      setLoadingText('We are connecting your account')

      apiAuthClient
        .post(endpoints.handleOauthLoggedUser, {
          provider: partnerFullName,
          authCode: authLbCode,
          requestUri: validPersistentBbuData?.requestUri,
        })
        .then(() => {
          setLoadingText('We are setting up your bridge')
          handleFinalStep()
        })
        .catch((error) => {
          setGenericApiError(
            getApiErrorMessage({
              error: error,
            })
          )
        })
    }
  }, [isMountedInLoggedRoute, surveyNeeded, hasJustCompletedSurvey])

  // SHOW ERROR
  if (errorMissingStartingData || apiError || genericApiError) {
    return (
      <div>
        <Container>
          <Message $header={'Error'} $status={'error'}>
            {errorMissingStartingData && <p>{errorMissingStartingData}</p>}
            {apiError && (
              <div
                dangerouslySetInnerHTML={{
                  // error messages from api could arrive as html
                  __html: apiError,
                }}
              />
            )}
            {genericApiError && (
              <div
                dangerouslySetInnerHTML={{
                  // error messages from api could arrive as html
                  __html: genericApiError,
                }}
              />
            )}

            {/* api can return a link for the retry inside the error message (as html) */}
            {/* It's not the best solution, but it's the good enough for now */}
            {apiError.includes('<a ') ? null : (
              <Button
                className={'mt-1'}
                $variant={'secondary'}
                onClick={() => {
                  if (validPersistentBbuData?.requestUri) {
                    // we already validate `requestUri` so it's safe to do build a `new URL`
                    navigate(
                      new URL(validPersistentBbuData.requestUri).pathname
                    )
                    return
                  }

                  if (
                    partnerFullName &&
                    retryButtonPartnerLink[partnerFullName]
                  ) {
                    window.location.href =
                      retryButtonPartnerLink[partnerFullName]
                  } else {
                    window.location.reload()
                  }
                }}
              >
                Retry
              </Button>
            )}
          </Message>
        </Container>
      </div>
    )
  }

  return (
    <>
      <FastAppSurvey
        isLoading={isLoading}
        step={progressStep}
        integrationsData={{
          source: validPersistentBbuData?.source,
          destination: validPersistentBbuData?.destination,
        }}
        loadingText={loadingText}
        onSubmitSuccess={() => {
          setHasJustCompletedSurvey(true)
          localStorage.removeItem(
            PersistentLocalStorageKeys.bbuPersistentUserStatus
          )
          setIsLoading(true)
          setProgressStep(2)
          setLoadingText('We are creating your bridge')
          handleFinalStep()
        }}
      />
    </>
  )
}
