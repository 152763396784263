import React from 'react'
import styled from 'styled-components'

export type InputFeedbackStatus = {
  error?: string
  success?: string
}

export interface InputFeedbackProps {
  $status: InputFeedbackStatus
}

const InputText = styled.p<{
  variant: 'success' | 'error'
}>`
  font-size: 0.9rem;
  text-align: left;
  color: ${({ variant, theme }) => {
    switch (variant) {
      case 'success':
        return theme.input.success.borderColor
      case 'error':
        return theme.input.error.borderColor
      default:
        return theme.input.default.borderColor
    }
  }};
`

export const InputFeedback: React.FC<InputFeedbackProps> = ({ $status }) => {
  return (
    <>
      {$status?.error && (
        <InputText data-testid={'input'} data-error='true' variant='error'>
          {$status?.error}
        </InputText>
      )}
      {$status?.success && (
        <InputText variant='success'>{$status?.success}</InputText>
      )}
    </>
  )
}
