import React, { ReactNode } from 'react'
import { CheckboxStyled } from './styled'
import {
  InputFeedback,
  InputFeedbackStatus,
} from '@components/Basic/InputFeedback'

export interface CheckboxProps {
  name: string
  value?: string
  checked?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  $status?: InputFeedbackStatus
  children?: ReactNode
}
export const Checkbox: React.FC<CheckboxProps> = ({
  value,
  checked,
  onChange,
  name,
  disabled,
  children,
  $status,
  ...inputProps
}) => {
  return (
    <React.Fragment>
      <CheckboxStyled className={`${disabled ? 'disabled' : ''}`}>
        <label className='container'>
          {children}
          <input
            disabled={disabled}
            type='checkbox'
            name={name}
            id={name}
            value={value}
            checked={checked}
            onChange={onChange}
            {...inputProps}
          />
          <span className='checkmark' />
        </label>
      </CheckboxStyled>
      {$status && <InputFeedback $status={$status} />}
    </React.Fragment>
  )
}
