import React, { InputHTMLAttributes } from 'react'
import { TextareaWrapper, TextareaStyled } from './styled'
import { Label } from '@components/Form/Label'
import {
  InputFeedbackStatus,
  InputFeedback,
} from '@components/Basic/InputFeedback'

export interface InputFieldAttrProps
  extends InputHTMLAttributes<HTMLTextAreaElement> {
  $status?: InputFeedbackStatus
  name: string
  label?: string
}

export const Textarea: React.FC<InputFieldAttrProps> = ({
  label,
  $status,
  ...props
}) => {
  const inputId = `${props.name}`

  return (
    <TextareaWrapper>
      {label && (
        <Label $disabled={props.disabled} htmlFor={inputId} $label={label} />
      )}
      <TextareaStyled $status={$status} {...props} />
      {$status && <InputFeedback $status={$status} />}
    </TextareaWrapper>
  )
}
